<template>
  <v-app>
    <v-main>
      <template v-if="!isLoading">
        <v-snackbar top color="green" v-model="couponSuccess" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>Rewards imported successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="couponSuccess = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="couponError" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while importing the coupons. Please check your file and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="couponError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="green" v-model="logosSuccess" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>Logos set successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="logosSuccess = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="logosError" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while setting the logos. Please check your data and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="logosError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="green" v-model="sponsorSuccess" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>Sponsors Logos set successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="sponsorSuccess = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="sponsorError" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while setting the sponsors logos. Please check your data and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="sponsorError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="green" v-model="landingSuccess" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>Landing Page set successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="landingSuccess = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="landingError" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while setting the landing page. Please check your data and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="landingError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-dialog persistent v-model="importDialog" max-width="520px" opacity="1">
          <v-card>
            <v-card-title class="text-h5"> Channels Import Summary </v-card-title>
            <v-card-text
              ><b>Number of Partners: {{ importPartnersCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Number of Affiliates: {{ importAffiliatesCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Upload Count: {{ importCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Users Successfully Uploaded: {{ importResult?.users_imported?.length }}</b></v-card-text
            >
            <v-card-text
              ><b>Users Not Uploaded: {{ importResult?.users_to_import?.length - importResult?.users_imported?.length }}</b></v-card-text
            >
            <v-card-text>
              <b>Users Not In Database: {{ importResult?.users_not_in_database?.length }}</b>
              <br />
              <ul id="example-2">
                <li v-for="(channel, index) in importResult?.users_not_imported" :key="index">
                  {{ channel }}
                </li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="importDialog = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="couponImportDialog" max-width="520px" opacity="1">
          <v-card>
            <v-card-title class="text-h5"> Rewards Import Summary </v-card-title>
            <v-card-text
              ><b>Upload Count: {{ couponImportCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Unique Rewards Count: {{ couponCodesImportCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Live Rewards Count: {{ couponLiveImportCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Post Rewards Count: {{ couponPostImportCount }}</b></v-card-text
            >
            <v-card-text
              ><b>Creators Successfully Uploaded: {{ couponUsersImportCount }}</b></v-card-text
            >
            <v-card-text>
              <b>Levels Uploaded: {{ couponTiersImport.length }}</b>
              <br />
              <ul id="example-2">
                <li v-for="(tier, index) in couponTiersImport" :key="index">Tier {{ tier.Tier }}</li>
              </ul>
            </v-card-text>
            <v-card-text>
              <b>Rewards Count per Level:</b>
              <br />
              <ul id="example-2">
                <li v-for="(tier, index) in couponPerTiersImport" :key="index">Tier {{ tier[0].Tier }}: {{ tier.length }}</li>
              </ul>
            </v-card-text>
            <v-card-text>
              <b>Rewards Not Uploaded: 0</b>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="couponImportDialog = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="dashboard-creators-web" :style="'height: ' + (screenHeight < 900 || screenWidth < 1080 ? 1000 : toggleOpen ? openCampaignsHeight + 1342 : 2092) + 'px !important'">
          <div class="bg-main"></div>
          <div class="bg-open"></div>
          <div class="content">
            <div class="header">
              <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo" src="/imgs/twitch-logo.png" /></a>
              <div class="creator-photo">
                <div class="photo">
                  <v-tooltip bottom color="#9146ff" v-if="user !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar style="cursor: pointer" @click="logout" class="ml-2" v-bind="attrs" v-on="on">
                        <v-img :src="user?.profile_image_url"></v-img>
                      </v-avatar>
                    </template>
                    <span class="logout">{{ $vuetify.lang.t('$vuetify.logout') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <template v-if="user !== null">
                <div class="creator-username">
                  <template v-if="screenHeight > 900 && screenWidth > 1080">
                    <template v-if="user?.role === 'super'">
                      <div class="user-mgmt" @click="viewUserMgmt">
                        <v-btn class="ma-2" color="#7d5bbe" dark> <v-icon dark left large>mdi-account-group</v-icon>&nbsp;&nbsp; User Mgmt </v-btn>
                      </div>
                    </template>
                  </template>
                  {{ user?.display_name }}
                </div>
              </template>
            </div>
            <div class="welcome">{{ $vuetify.lang.t('$vuetify.heading_welcome') }}</div>
            <template v-if="toggleOpen">
              <div class="campaign">
                <div class="campaign-text">
                  <div class="campaign-name-label" @click="toggle">
                    {{ $vuetify.lang.t('$vuetify.open_campaigns') }}
                  </div>
                </div>
                <div class="new-bg"></div>
                <div class="new" @click="newCampaign">+</div>
                <div class="campaign-list">
                  <div v-for="(openCampaign, i) in openCampaigns" :key="i">
                    <template v-if="showIndex === i">
                      <div class="opened-campaign-panel" :style="'top: -695px !important'">
                        <div class="rectangle-12" :style="user?.role === 'viewer' ? 'height: 1343px !important;' : ''"></div>
                        <div class="rectangle-2"></div>
                        <div class="rectangle-14"></div>
                        <div class="current-score">
                          <svg class="rectangle-6" width="1316" height="120" viewBox="0 0 1316 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 40C0 17.9086 17.9086 0 40 0H1276C1298.09 0 1316 17.9086 1316 40V120H0V40Z" fill="#BEFF00" />
                          </svg>
                          <div class="exp-ui-test" @click="setShowIndex(-1)">{{ openCampaign?.country !== null ? openCampaign?.name + ' | ' + openCampaign?.country : openCampaign?.name }}</div>
                          <div class="opened-campaign-panel-minus" @click="setShowIndex(-1)">-</div>
                        </div>
                        <div class="group-25">
                          <div class="start-date">Start date</div>
                          <div class="_2023-2-17-12-00-00-pm">
                            {{ new Date(openCampaign.start_date).toLocaleDateString() }}
                            {{ new Date(openCampaign.start_date).toLocaleTimeString() }}
                          </div>
                        </div>
                        <div class="group-41">
                          <div class="channel-count">Channel Count</div>
                          <div class="_0">{{ openCampaign.score_count }}</div>
                        </div>
                        <div class="campaign-end-date">
                          <div class="rectangle-3"></div>
                        </div>
                        <div class="group-26">
                          <div class="end-date2">End date</div>
                          <div class="_2023-2-17-12-00-00-pm2">
                            {{ new Date(openCampaign.end_date).toLocaleDateString() }}
                            {{ new Date(openCampaign.end_date).toLocaleTimeString() }}
                          </div>
                        </div>
                        <div class="time-left">
                          <div class="rectangle-4"></div>
                        </div>
                        <div class="time-left">
                          <div class="rectangle-42"></div>
                        </div>
                        <div class="group-24">
                          <div class="time-left2">Time Left</div>
                          <div class="_13-h-20-min-43-sec">
                            <template v-if="campaignStarted(openCampaign.start_date)">
                              <count-down :currentTime="new Date().getTime()" :startTime="new Date().getTime()" :endTime="new Date(openCampaign.end_date).getTime()" :tipText="''" :tipTextEnd="''" :endText="''" :dayTxt="' D '" :hourTxt="' H '" :minutesTxt="' MIN '" :secondsTxt="' SEC '">
                              </count-down>
                            </template>
                            <template v-else> Starting soon </template>
                          </div>
                        </div>
                        <div class="group-53">
                          <div class="country">Country</div>
                          <div class="tw">{{ openCampaign?.country ?? 'N/A' }}</div>
                        </div>
                        <div class="rectangle-5"></div>
                        <div class="campaign-includes">Includes</div>
                        <div class="subs-bits">{{ openCampaign.include_subs ? 'Subs' : '' }}{{ ' ' }}{{ openCampaign.include_bits ? 'Bits' : '' }}{{ ' ' }}{{ openCampaign.include_raids ? 'Raids' : '' }}</div>
                        <div class="next-lvl-score">
                          <div class="rectangle-8"></div>
                        </div>
                        <div class="group-28">
                          <div class="tiers">Tiers</div>
                          <div class="_3-6-8">
                            <template v-for="(tier, index) in openCampaign.tiers">
                              <span :key="openCampaign.id + '::' + index">{{ openCampaign.tiers[index] }}&nbsp;</span></template
                            >
                          </div>
                        </div>
                        <div class="buttons4" @click="viewChannels(openCampaign)" :style="user?.role === 'viewer' ? 'left: 445px !important;' : ''">
                          <div class="tab-name4">View Channels</div>
                        </div>
                        <template v-if="user?.role !== null && user?.role !== 'viewer'">
                          <div class="buttons8" @click="newChannel(openCampaign)">
                            <div class="tab-name5">Add Channels</div>
                          </div>
                        </template>
                        <div class="buttons9" @click="viewCoupons(openCampaign)" :style="user?.role === 'viewer' ? 'left: 790px !important;' : ''">
                          <div class="tab-name5">View Rewards</div>
                        </div>
                        <template v-if="user?.role !== null && user?.role !== 'viewer'">
                          <div class="buttons5" @click="uploadCoupons(openCampaign)">
                            <div class="tab-name5">Add Rewards</div>
                          </div>
                        </template>
                        <template v-if="user?.role !== null && user?.role !== 'viewer'">
                          <div class="buttons7" @click="updateSponsorLogos(openCampaign)">
                            <div class="tab-name5">Set Sponsors</div>
                          </div>
                        </template>
                        <template v-if="user?.role !== null && user?.role !== 'viewer'">
                          <div class="buttons6" @click="updateLogos(openCampaign)">
                            <div class="tab-name5">Set Overlay</div>
                          </div>
                        </template>
                        <template v-if="openCampaign.landing_page_enabled === true">
                          <div class="buttons-landing" @click="viewLanding(openCampaign)" :style="user?.role === 'viewer' ? 'left: 1125px !important;' : ''">
                            <div class="tab-name5">View Landing</div>
                          </div>
                          <template v-if="user?.role !== null && user?.role !== 'viewer'">
                            <div class="buttons-set-landing" @click="updateLanding(openCampaign)">
                              <div class="tab-name5">Set Landing</div>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="buttons-landing-disabled" :style="user?.role === 'viewer' ? 'left: 1125px !important;' : ''">
                            <div class="tab-name5">View Landing</div>
                          </div>
                          <template v-if="user?.role !== null && user?.role !== 'viewer'">
                            <div class="buttons-set-landing-disabled">
                              <div class="tab-name5">Set Landing</div>
                            </div>
                          </template>
                        </template>
                        <template v-if="user?.role === 'super' || user?.role === 'admin'">
                          <div class="buttonsEdit" v-if="new Date(openCampaign.start_date) > new Date() || openCampaign.name.toLowerCase().includes('test') || openCampaign.is_test" @click="updateEdit(openCampaign)">
                            <div class="tab-name6">Edit</div>
                          </div>
                        </template>
                        <template v-if="user?.role === 'super' || user?.role === 'admin'">
                          <div
                            class="buttons10"
                            v-if="new Date(openCampaign.start_date) > new Date() || openCampaign.name.toLowerCase().includes('test') || openCampaign.is_test"
                            @click="
                              deleteCampaignConfirm({
                                campaignId: openCampaign.id
                              })
                            "
                          >
                            <div class="tab-name6">Delete</div>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <div class="closed-campaign-panel">
                        <svg class="closed-campaign-panel-bg" width="1316" height="120" viewBox="0 0 1316 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 39C0 17.4609 17.4609 0 39 0H1277C1298.54 0 1316 17.4609 1316 39V81C1316 102.539 1298.54 120 1277 120H39C17.4609 120 0 102.539 0 81V39Z" fill="#BEFF00" />
                        </svg>
                        <div class="closed-campaign-panel-label" @click="setShowIndex(i)">{{ openCampaign?.country !== null ? openCampaign?.name + ' | ' + openCampaign?.country : openCampaign?.name }}</div>
                        <div class="closed-campaign-panel-plus" @click="setShowIndex(i)">+</div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="open-campaigns">
                <div class="open-bg"></div>
                <div class="open-label" @click="toggle">{{ $vuetify.lang.t('$vuetify.open_campaigns') }}</div>
              </div>
            </template>
            <template v-if="toggleOpen">
              <div class="bg-closed" :style="'top: ' + (openCampaignsHeight + 630) + 'px !important'"></div>
              <div class="closed">
                <div class="closed-title" @click="toggle" :style="'top: ' + (openCampaignsHeight + 717) + 'px !important'">{{ $vuetify.lang.t('$vuetify.closed_campaigns') }}</div>
                <template v-if="closedCampaigns.length > 0">
                  <template v-for="(year, index) in closedCampaignYears" :value="year">
                    <div :class="'closed-year-' + index" :key="year" @click="filterByYear(year)" :style="'top: ' + (openCampaignsHeight + 850) + 'px !important'">
                      <div class="closed-year-value">{{ year }}</div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="bg-closed-open" :style="'height: ' + (screenHeight < 900 || screenWidth < 1080 ? (!toggleOpen ? 1 * 330 - 200 : 900) : 1200) + 'px !important'"></div>
              <div class="close-cam">
                <div class="closed-open-title" @click="toggle">{{ $vuetify.lang.t('$vuetify.closed_campaigns') }}</div>
                <template v-if="closedCampaigns.length > 0">
                  <div class="closed-select">
                    <div class="closed-select-year">
                      <v-select v-model="year" :items="closedCampaignYears" solo height="64"></v-select>
                    </div>
                  </div>
                </template>
              </div>
              <template v-if="filteredClosedCampaigns?.length > 0">
                <div class="closed-table">
                  <div>
                    <div class="campaign-name">{{ $vuetify.lang.t('$vuetify.campaign_name') }}</div>
                    <div class="level-completed">Country</div>
                    <div class="score">Channel Count</div>
                    <div class="includes">{{ $vuetify.lang.t('$vuetify.campaign_includes') }}</div>
                    <div class="star-date">{{ $vuetify.lang.t('$vuetify.campaign_start_date') }}</div>
                    <div class="end-date">{{ $vuetify.lang.t('$vuetify.campaign_end_date') }}</div>
                  </div>
                  <template v-for="(closedCampaign, index) in filteredClosedCampaigns">
                    <div :key="index">
                      <div :class="'row-bg-' + index"></div>
                      <template v-if="closedCampaign.details_url !== null || closedCampaign.landing_page_enabled === true">
                        <template v-if="closedCampaign.details_url !== null">
                          <a class="details-link" :href="closedCampaign.details_url" target="_blank">
                            <div :class="'row-rewards-bg-' + index">
                              <div class="row-button-label">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                            </div>
                          </a>
                        </template>
                        <template v-else>
                          <a class="details-link" @click="viewLanding(closedCampaign)">
                            <div :class="'row-rewards-bg-' + index">
                              <div class="row-button-label">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                            </div>
                          </a>
                        </template>
                      </template>
                      <template v-else>
                        <div :class="'row-rewards-bg-' + index" style="background-color: #353535">
                          <div class="row-button-label" style="color: #222222">&nbsp;</div>
                        </div>
                      </template>
                      <template>
                        <a class="details-link" @click="viewChannels(closedCampaign)">
                          <div :class="'row-details-bg-' + index">
                            <div class="row-button-label">View</div>
                          </div>
                        </a>
                      </template>
                      <template v-if="closedCampaign.name.length > 14">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div :class="'text-truncate row-name-' + index" style="max-width: 190px" v-bind="attrs" v-on="on">{{ closedCampaign.name }}</div>
                          </template>
                          <span class="logout">{{ closedCampaign.name }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div :class="'text-truncate row-name-' + index" style="max-width: 190px">{{ closedCampaign.name }}</div>
                      </template>
                      <div :class="'row-level-' + index">{{ closedCampaign.country ?? 'N/A' }}</div>
                      <div :class="'row-score-' + index">{{ closedCampaign.score_count }}</div>
                      <div :class="'row-includes-' + index">{{ closedCampaign.include_subs ? 'Subs' : '' }} {{ closedCampaign.include_bits ? 'Bits' : '' }} {{ closedCampaign.include_raids ? 'Raids' : '' }}</div>
                      <div :class="'row-start-' + index">{{ new Date(closedCampaign.start_date).toLocaleDateString() }}</div>
                      <div :class="'row-end-' + index">{{ new Date(closedCampaign.end_date).toLocaleDateString() }}</div>
                    </div>
                  </template>
                  <div class="pagination" :style="'top: ' + ((filteredClosedCampaigns.length || 1) * 130 + 860) + 'px !important'">
                    <v-pagination v-model="closedPage" :length="numberOfPages" color="#424242"></v-pagination>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="closed-no-data">{{ $vuetify.lang.t('$vuetify.no_campaigns_available') }}</div>
              </template>
            </template>
            <div class="no-mobile-view">Mobile Version Not Supported:<br />Please access the desktop version of GlitchEXP to continue</div>
            <div>
              <img class="live-pill" src="/imgs/live-pill.png" />
              <img class="purple-bits" src="/imgs/purple-bits.png" />
            </div>
            <div class="footer">
              <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" :style="'top: ' + (toggleOpen ? openCampaignsHeight + 1092 : 1842) + 'px !important'" /></a>
              <div>
                <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank" :style="'top: ' + (toggleOpen ? openCampaignsHeight + 1240 : 1990) + 'px !important'">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
              </div>
              <div class="partner" :style="'top: ' + (toggleOpen ? openCampaignsHeight + 1240 : 1990) + 'px !important'">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
              <div>
                <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank" :style="'top: ' + (toggleOpen ? openCampaignsHeight + 1240 : 1990) + 'px !important'">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
              </div>
              <div class="trade-mark" :style="'top: ' + (toggleOpen ? openCampaignsHeight + 1204 : 1954) + 'px !important'">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
            </div>
          </div>
        </div>
        <template>
          <v-row dense v-if="user !== null">
            <v-col cols="12">
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="newChannelDialog && screenHeight > 900" opacity="1">
                <v-card v-if="newChannelCampaign !== null">
                  <v-toolbar>
                    <v-toolbar-title>{{ newChannelCampaign.name }} - Add Channels</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancelNewChannel"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-switch v-model="isSearch" :label="isSearch ? 'Search' : 'Bulk Import'"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="!isSearch">
                        <v-col cols="9">
                          <v-textarea v-model="importText" label="Bulk Import"></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <v-btn color="primary" rounded @click="doImport"> Bulk Import </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="isSearch">
                        <v-col cols="10" class="mr-10">
                          <v-text-field v-model="newChannelSearch" label="Search"></v-text-field>
                        </v-col>
                        <v-col cols="1" class="mt-3">
                          <v-btn color="primary" rounded @click="doSearch"> Search </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="isSearch">
                        <template v-for="result in searchResults">
                          <v-col lg="4" :key="result.id">
                            <v-card elevation="5" outlined rounded class="rounded-xl">
                              <v-img height="283px" :src="result.thumbnail_url" class="rounded-xl" />
                              <v-card-actions class="mt-3 mb-3">
                                <v-toolbar-title class="ml-2"
                                  ><b>{{ result?.display_name }}</b></v-toolbar-title
                                >
                                <v-spacer></v-spacer>
                                <v-spacer></v-spacer>
                                <div class="add-new-bg"></div>
                                <div class="add-new" @click="addNewChannel(result.id)">+</div>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="couponDialog && screenHeight > 900" opacity="1">
                <v-card v-if="couponCampaign !== null">
                  <v-toolbar>
                    <v-toolbar-title>{{ couponCampaign.name }} - Add Rewards</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancelUploadCoupons"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12"></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="10" class="mr-10">
                          <v-file-input show-size label="File input" accept=".csv" @change="handleUploadCoupons($event)"></v-file-input>
                        </v-col>
                        <v-col cols="1" class="mt-3">
                          <v-btn color="primary" rounded @click="doImportCoupons"> Import </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="logosDialog && screenHeight > 900" opacity="1">
                <v-card v-if="logosCampaign !== null">
                  <v-toolbar>
                    <v-toolbar-title>{{ logosCampaign.name }} - Set Overlay</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancelUpdateLogos"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="logosOverlayThemeLabel" label="Overlay Theme One Label"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="logosOverlayThemeLightLabel" label="Overlay Theme Two Label"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-select v-model="logosOverlayThemeImageUrl" :items="logosOverlayThemeImages" label="Overlay Theme One Image File"></v-select>
                        </v-col>
                        <v-col cols="2" v-if="logosOverlayThemeImageUrl"><v-img :width="300" :src="`/imgs/${logosOverlayThemeImageUrl}`" style="top: 30px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="4">
                          <v-select v-model="logosOverlayThemeLightImageUrl" :items="logosOverlayThemeImages" label="Overlay Theme Two Image File"></v-select>
                        </v-col>
                        <v-col cols="2" v-if="logosOverlayThemeLightImageUrl"><v-img :width="300" :src="`/imgs/${logosOverlayThemeLightImageUrl}`" style="top: 30px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-select v-model="logosProgressBarImageUrl" :items="logosProgressBarImages" label="Progress Bar Theme One Image File"></v-select>
                        </v-col>
                        <v-col cols="2" v-if="logosProgressBarImageUrl"><v-img :width="300" :src="`/imgs/${logosProgressBarImageUrl}`" style="top: 5px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="4">
                          <v-select v-model="logosProgressBarLightImageUrl" :items="logosProgressBarImages" label="Progress Bar Theme Two Image File"></v-select>
                        </v-col>
                        <v-col cols="2" v-if="logosProgressBarLightImageUrl"><v-img :width="300" :src="`/imgs/${logosProgressBarLightImageUrl}`" style="top: 5px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-file-input show-size label="Campaign Logo Theme One Image File" accept="image/*" @change="fileStackOnSelectFile($event, 'campaignLogo')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="logosLogoImageUrl"><v-img :width="300" :src="logosLogoImageUrl" style="top: 40px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="4">
                          <v-file-input show-size label="Campaign Logo Theme Two Image File" accept="image/*" @change="fileStackOnSelectFile($event, 'campaignLightLogo')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="logosLogoLightImageUrl"><v-img :width="300" :src="logosLogoLightImageUrl" style="top: 40px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="40">
                          <v-file-input show-size label="Sponsor Logo Theme One Image File" accept="image/*" @change="fileStackOnSelectFile($event, 'sponsorLogo')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="logosSponsorImageUrl"><v-img :width="300" :src="logosSponsorImageUrl" style="top: 40px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="40">
                          <v-file-input show-size label="Sponsor Logo Theme Two Image File" accept="image/*" @change="fileStackOnSelectFile($event, 'sponsorLightLogo')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="logosSponsorLightImageUrl"><v-img :width="300" :src="logosSponsorLightImageUrl" style="top: 40px"></v-img></v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="logosSponsorText" label="Sponsor Text"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="7">
                          <v-text-field v-model="logosOverlayMessageText" label="Overlay Message Text"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <template v-if="logosOverlayMessageText">
                            <template v-if="messageStarted && !messageExpired">
                              <v-chip color="green" text-color="white"> Active </v-chip>
                            </template>
                            <template v-else>
                              <v-chip color="red" text-color="white"> Inactive </v-chip>
                            </template>
                          </template>
                          <template v-else>
                            <v-chip color="gray" text-color="white"> Not Set </v-chip>
                          </template>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field type="number" step="1" min="5" v-model="logosOverlayMessageInterval" label="Overlay Interval (in minutes)"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field type="number" step="1" min="1" v-model="logosOverlayMessageDuration" label="Overlay Duration (in minutes)"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field label="Overlay Message Start Date" v-model="logosOverlayMessageStartDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="logosOverlayMessageStartDate" @input="startDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-menu ref="menu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="logosOverlayMessageStartTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="logosOverlayMessageStartTime" label="Overlay Message Start Time" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="startTimeMenu" v-model="logosOverlayMessageStartTime" full-width @click:minute="$refs.menu.save(logosOverlayMessageStartTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field label="Overlay Message End Date" v-model="logosOverlayMessageEndDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="logosOverlayMessageEndDate" @input="endDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-menu ref="menu2" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="logosOverlayMessageEndTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="logosOverlayMessageEndTime" label="Overlay Message End Time" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="endTimeMenu" v-model="logosOverlayMessageEndTime" full-width @click:minute="$refs.menu2.save(logosOverlayMessageEndTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn color="#7d5bbe" rounded class="mr-2 mb-2" @click="resetUpdateLogos"> Reset </v-btn>
                    <file-stack :files="fileStackFiles" @success="fileStackOnSuccess" @error="fileStackOnError">
                      <v-btn color="primary" rounded class="ml-2 mb-2"> Set </v-btn>
                    </file-stack>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="sponsorDialog && screenHeight > 900" opacity="1">
                <v-card v-if="sponsorCampaign !== null">
                  <v-toolbar>
                    <v-toolbar-title>{{ sponsorCampaign.name }} - Set Sponsors</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancelUpdateSponsorLogos"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 1</v-chip>
                          <v-file-input show-size label="Level 1 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier1')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel1ImageUrl"><v-img :width="300" :src="sponsorLevel1ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 1</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel1Name" label="Level 1 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 2</v-chip>
                          <v-file-input show-size label="Level 2 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier2')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel2ImageUrl"><v-img :width="300" :src="sponsorLevel2ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 2</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel2Name" label="Level 2 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 3</v-chip>
                          <v-file-input show-size label="Level 3 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier3')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel3ImageUrl"><v-img :width="300" :src="sponsorLevel3ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 3</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel3Name" label="Level 3 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 4</v-chip>
                          <v-file-input show-size label="Level 4 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier4')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel4ImageUrl"><v-img :width="300" :src="sponsorLevel4ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 4</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel4Name" label="Level 4 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 5</v-chip>
                          <v-file-input show-size label="Level 5 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier5')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel5ImageUrl"><v-img :width="300" :src="sponsorLevel5ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 5</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel5Name" label="Level 5 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 6</v-chip>
                          <v-file-input show-size label="Level 6 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier6')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel6ImageUrl"><v-img :width="300" :src="sponsorLevel6ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 6</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel6Name" label="Level 6 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 7</v-chip>
                          <v-file-input show-size label="Level 7 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier7')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel7ImageUrl"><v-img :width="300" :src="sponsorLevel7ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 7</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel7Name" label="Level 7 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 8</v-chip>
                          <v-file-input show-size label="Level 8 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier8')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel8ImageUrl"><v-img :width="300" :src="sponsorLevel8ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 8</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel8Name" label="Level 8 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 9</v-chip>
                          <v-file-input show-size label="Level 9 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier9')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel9ImageUrl"><v-img :width="300" :src="sponsorLevel9ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 9</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel9Name" label="Level 9 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 10</v-chip>
                          <v-file-input show-size label="Level 10 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier10')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel10ImageUrl"><v-img :width="300" :src="sponsorLevel10ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 10</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel10Name" label="Level 10 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 11</v-chip>
                          <v-file-input show-size label="Level 11 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier11')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel11ImageUrl"><v-img :width="300" :src="sponsorLevel11ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 11</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel11Name" label="Level 11 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Level 12</v-chip>
                          <v-file-input show-size label="Level 12 Image" accept="image/*" @change="fileStackSponsorOnSelectFile($event, 'tier12')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="sponsorLevel12ImageUrl"><v-img :width="300" :src="sponsorLevel12ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="1"><v-chip color="primary" class="mt-4 mr-2">Level 12</v-chip></v-col>
                        <v-col cols="4">
                          <v-text-field v-model="sponsorLevel12Name" label="Level 12 Name"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn color="#7d5bbe" rounded class="mr-2 mb-2" @click="resetUpdateSponsorLogos"> Reset </v-btn>
                    <file-stack :files="fileStackSponsorFiles" @success="fileStackSponsorOnSuccess" @error="fileStackSponsorOnError">
                      <v-btn color="primary" rounded class="ml-2 mb-2"> Set </v-btn>
                    </file-stack>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1400" :value="landingDialog && screenHeight > 900" opacity="1">
                <v-card v-if="landingCampaign !== null">
                  <v-toolbar>
                    <v-toolbar-title>{{ landingCampaign.name }} - Set Landing Page</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" rounded class="mr-4" @click="viewFaqs(landingCampaign)"> FAQs </v-btn>
                    <v-btn color="orange" rounded class="mr-4" @click="viewMultipleChoices(landingCampaign)"> Secondary Qs </v-btn>
                    <v-btn color="success" rounded class="mr-4" @click="viewWhiteListUsers(landingCampaign)"> White List </v-btn>
                    <v-btn color="brown" rounded class="mr-4" @click="viewWaitListUsers(landingCampaign)"> Wait List </v-btn>
                    <v-btn color="error" rounded @click="cancelUpdateLanding"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field v-model="landingCustomSubtitle" label="Custom EXP Subtitle"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-model="landingDates" label="Campaign Dates" :placeholder="formattedStartDate(landingCampaign) + ' - ' + formattedEndDate(landingCampaign)"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-switch v-model="landingSignUpEnabled" label="Sign Up Enabled"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field v-model="landingFaqsTitle" label="Custom FAQs Title"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-model="landingMultipleChoicesTitle" label="Custom Secondary Qs Title"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="landingCarouselInterval" label="Carousel Interval" :disabled="user?.role !== 'super' && user?.role !== 'admin'" type="number"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Hero Image</v-chip>
                          <v-file-input show-size label="Hero Image File" accept="image/*" @change="fileStackLandingOnSelectFile($event, 'banner')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="landingBannerImageUrl"><v-img :width="300" :src="landingBannerImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="4" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Carousel 1</v-chip>
                          <v-file-input show-size label="Carousel 1 Image File" accept="image/*" @change="fileStackLandingOnSelectFile($event, 'prize1')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="landingPrize1ImageUrl"><v-img :width="300" :src="landingPrize1ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Carousel 2</v-chip>
                          <v-file-input show-size label="Carousel 2 Image File" accept="image/*" @change="fileStackLandingOnSelectFile($event, 'prize2')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="landingPrize2ImageUrl"><v-img :width="300" :src="landingPrize2ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                        <v-col cols="4" class="d-flex">
                          <v-chip color="primary" class="mt-4 mr-2">Carousel 3</v-chip>
                          <v-file-input show-size label="Carousel 3 Image File" accept="image/*" @change="fileStackLandingOnSelectFile($event, 'prize3')"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="landingPrize3ImageUrl"><v-img :width="300" :src="landingPrize3ImageUrl" style="top: 40px"></v-img> </v-col>
                        <v-col cols="2" v-else></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize1Name" label="Prize 1 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize2Name" label="Prize 2 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize3Name" label="Prize 3 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize4Name" label="Prize 4 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize5Name" label="Prize 5 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize6Name" label="Prize 6 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize7Name" label="Prize 7 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize8Name" label="Prize 8 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize9Name" label="Prize 9 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize10Name" label="Prize 10 Name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize11Name" label="Prize 11 Name"></v-text-field>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="4">
                          <v-text-field v-model="landingPrize12Name" label="Prize 12 Name"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn color="#7d5bbe" rounded class="mr-2 mb-2" @click="resetUpdateLanding"> Reset </v-btn>
                    <file-stack :files="fileStackLandingFiles" @success="fileStackLandingOnSuccess" @error="fileStackLandingOnError">
                      <v-btn color="primary" rounded class="ml-2 mb-2"> Set </v-btn>
                    </file-stack>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="overlay && screenHeight > 900" opacity="1">
                <v-card>
                  <v-toolbar>
                    <v-toolbar-title> New Campaign </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancel"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field v-model="name" label="Name (English)"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-model="localName" label="Local Name"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-select v-model="country" :items="countries" label="Country"></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="9">
                          <v-text-field v-model="detailsUrl" label="Details Page URL" :disabled="landingPageEnabled === true"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-switch v-model="landingPageEnabled" label="Landing Page Enabled"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-alert type="info"
                            >Your timezone,
                            {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
                            will be used to calculate start and end times of the campaign, this is separate from the timezone selection for Raid cutoffs.</v-alert
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field label="Start Date" v-model="startDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="startDate" @input="startDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu ref="menu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="startTime" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                            </template>
                            <v-time-picker v-if="startTimeMenu" v-model="startTime" full-width @click:minute="$refs.menu.save(startTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field label="End Date" v-model="endDate" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu ref="menu2" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="endTime" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                            </template>
                            <v-time-picker v-if="endTimeMenu" v-model="endTime" full-width @click:minute="$refs.menu2.save(endTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier1" type="number" label="Level 1"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier2" type="number" label="Level 2"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier3" type="number" label="Level 3"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier4" type="number" label="Level 4"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier5" type="number" label="Level 5"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier6" type="number" label="Level 6"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier7" type="number" label="Level 7"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier8" type="number" label="Level 8"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier9" type="number" label="Level 9"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier10" type="number" label="Level 10"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier11" type="number" label="Level 11"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field :rules="[rules.tiers]" v-model="tier12" type="number" label="Level 12"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">
                          <v-text-field v-model="link1Name" label="Link #1 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link1Url" label="Link #1 URL"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link2Name" label="Link #2 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link2Url" label="Link #2 URL"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link3Name" label="Link #3 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link3Url" label="Link #3 URL"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch v-model="includeSubs" :disabled="includeRaids" label="Include Subs Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch v-model="includeBits" :disabled="includeRaids" label="Include Bits Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch v-model="includeRaids" label="Include Raid Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="includeRaids">
                        <v-col cols="4">
                          <v-select :items="raidLevels" label="Raid Targets" item-text="name" item-value="value" v-model="raidLevel" dense></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select :items="timezones" item-text="id" item-value="id" label="Timezone" dense flat v-model="raidTimezone" readonly></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select :items="languages" item-text="name" item-value="value" label="Language" dense flat v-model="raidTag" readonly></v-select>
                        </v-col>
                      </v-row>
                      <v-row v-if="includeRaids">
                        <v-col cols="4">
                          <v-text-field v-model="campaign_raid_limit" label="Campaign Raid Limit"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="daily_raid_limit" label="Daily Raid Limit"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="points_per_raider" label="Points per Raider"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn color="#7d5bbe" class="mr-2 mb-2" rounded @click="createTestCampaign"> Create Test Campaign </v-btn>
                    <v-btn color="primary" class="ml-2 mb-2" rounded @click="create"> Create </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="editDialog && screenHeight > 900" opacity="1">
                <v-card>
                  <v-toolbar>
                    <v-toolbar-title> Edit Campaign </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="error" rounded @click="cancelUpdateEdit"> Cancel </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field v-model="name" label="Name (English)"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-model="localName" label="Local Name"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-select v-model="country" :items="countries" label="Country" disabled></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="9">
                          <v-text-field v-model="detailsUrl" label="Details Page URL" disabled></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-switch v-model="landingPageEnabled" label="Landing Page Enabled" disabled></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-alert type="info"
                            >Your timezone,
                            {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
                            will be used to calculate start and end times of the campaign, this is separate from the timezone selection for Raid cutoffs.</v-alert
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-menu disabled v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field disabled label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker disabled v-model="startDate" @input="startDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu disabled ref="menu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field disabled v-model="startTime" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker disabled v-if="startTimeMenu" v-model="startTime" full-width @click:minute="$refs.menu.save(startTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-menu disabled v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field disabled label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker disabled v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu disabled ref="menu2" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field disabled v-model="endTime" label="Picker in menu" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker disabled v-if="endTimeMenu" v-model="endTime" full-width @click:minute="$refs.menu2.save(endTime)"></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 1"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 2"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 3"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 4"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 5"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 6"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 7"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 8"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 9"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 10"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 11"> </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field disabled type="number" label="Level 12"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">
                          <v-text-field v-model="link1Name" label="Link #1 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link1Url" label="Link #1 URL"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link2Name" label="Link #2 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link2Url" label="Link #2 URL"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link3Name" label="Link #3 Label"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="link3Url" label="Link #3 URL"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch disabled v-model="includeSubs" label="Include Subs Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch disabled v-model="includeBits" label="Include Bits Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch disabled v-model="includeRaids" label="Include Raid Exp"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="includeRaids">
                        <v-col cols="4">
                          <v-select :items="raidLevels" label="Raid Targets" item-text="name" item-value="value" v-model="raidLevel" dense></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select :items="timezones" item-text="id" item-value="id" label="Timezone" dense flat v-model="raidTimezone" readonly></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select :items="languages" item-text="name" item-value="value" label="Language" dense flat v-model="raidTag" readonly></v-select>
                        </v-col>
                      </v-row>
                      <v-row v-if="includeRaids">
                        <v-col cols="4">
                          <v-text-field v-model="campaign_raid_limit" label="Campaign Raid Limit"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="daily_raid_limit" label="Daily Raid Limit"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="points_per_raider" label="Points per Raider"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="ml-2 mb-2" rounded @click="doUpdateEdit"> Edit </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </template>
      </template>
      <template v-else>
        <v-overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FileStack from '../components/FileStack.vue'
import Papa from 'papaparse'
import { uniqBy, groupBy, toArray, sortBy, isEmpty } from 'lodash'
import CountDown from 'vue2-countdown'

export default {
  components: {
    FileStack,
    CountDown
  },
  data() {
    return {
      showLoggedIn: true,
      showNotLoggedIn: true,
      showOverlayUsed: true,
      showOverlayNotUsed: true,
      showMenu: false,
      showFilter: false,
      showCountry: null,
      policy: false,
      viewChannelsDialog: false,
      viewChannelsCampaign: null,
      newChannelDialog: false,
      newChannelCampaign: null,
      newChannelSearch: '',
      importText: '',
      importDialog: false,
      importResult: [],
      importCount: 0,
      importPartnersCount: 0,
      importAffiliatesCount: 0,
      isLoading: false,
      isSearch: true,
      couponImportDialog: false,
      couponImportCount: 0,
      couponCodesImportCount: 0,
      couponUsersImportCount: 0,
      couponLiveImportCount: 0,
      couponPostImportCount: 0,
      couponTiersImport: [],
      couponPerTiersImport: [],
      couponDialog: false,
      couponCampaign: null,
      couponFile: '',
      couponFileName: '',
      couponFileContent: null,
      couponFileParsed: false,
      couponSuccess: false,
      couponError: false,
      overlay: false,
      startDate: Date.now(),
      endDate: Date.now(),
      startTime: null,
      endTime: null,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      name: '',
      localName: '',
      includeSubs: true,
      includeBits: true,
      includeRaids: false,
      raidTimezone: 'Asia/Tokyo',
      raidTag: 'ja',
      raidLevel: '',
      detailsUrl: null,
      landingPageEnabled: false,
      link1Name: null,
      link1Url: null,
      link2Name: null,
      link2Url: null,
      link3Name: null,
      link3Url: null,
      country: null,
      countries: ['ANZ', 'JP', 'TW', 'HK', 'UK', 'ES', 'FR', 'DE', 'BR', 'MX', 'US', 'CA'],
      campaign_raid_limit: 1,
      daily_raid_limit: 1,
      points_per_raider: 1,
      tier1: 0,
      tier2: 0,
      tier3: 0,
      tier4: 0,
      tier5: 0,
      tier6: 0,
      tier7: 0,
      tier8: 0,
      tier9: 0,
      tier10: 0,
      tier11: 0,
      tier12: 0,
      rules: {
        required: (value) => !!value || 'Required.',
        tiers: (v) => v < 2147483647 || 'Max should not be above 2,147,483,647'
      },
      link: process.env.VUE_APP_BASE_API_URL,
      raidLevels: [
        { name: 'Partner only', value: 'partner' },
        { name: 'Partner & Affiliate only', value: 'affiliate' },
        { name: 'All', value: 'all' }
      ],
      languages: [
        { name: 'Abkhazian', value: 'ab' },
        { name: 'Afar', value: 'aa' },
        { name: 'Afrikaans', value: 'af' },
        { name: 'Akan', value: 'ak' },
        { name: 'Albanian', value: 'sq' },
        { name: 'Amharic', value: 'am' },
        { name: 'Arabic', value: 'ar' },
        { name: 'Aragonese', value: 'an' },
        { name: 'Armenian', value: 'hy' },
        { name: 'Assamese', value: 'as' },
        { name: 'Avaric', value: 'av' },
        { name: 'Avestan', value: 'ae' },
        { name: 'Aymara', value: 'ay' },
        { name: 'Azerbaijani', value: 'az' },
        { name: 'Bambara', value: 'bm' },
        { name: 'Bashkir', value: 'ba' },
        { name: 'Basque', value: 'eu' },
        { name: 'Belarusian', value: 'be' },
        { name: 'Bengali', value: 'bn' },
        { name: 'Bislama', value: 'bi' },
        { name: 'Bosnian', value: 'bs' },
        { name: 'Breton', value: 'br' },
        { name: 'Bulgarian', value: 'bg' },
        { name: 'Burmese', value: 'my' },
        { name: 'Catalan, Valencian', value: 'ca' },
        { name: 'Chamorro', value: 'ch' },
        { name: 'Chechen', value: 'ce' },
        { name: 'Chichewa, Chewa, Nyanja', value: 'ny' },
        { name: 'Chinese', value: 'zh' },
        {
          name: 'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
          value: 'cu'
        },
        { name: 'Chuvash', value: 'cv' },
        { name: 'Cornish', value: 'kw' },
        { name: 'Corsican', value: 'co' },
        { name: 'Cree', value: 'cr' },
        { name: 'Croatian', value: 'hr' },
        { name: 'Czech', value: 'cs' },
        { name: 'Danish', value: 'da' },
        { name: 'Divehi, Dhivehi, Maldivian', value: 'dv' },
        { name: 'Dutch, Flemish', value: 'nl' },
        { name: 'Dzongkha', value: 'dz' },
        { name: 'English', value: 'en' },
        { name: 'Esperanto', value: 'eo' },
        { name: 'Estonian', value: 'et' },
        { name: 'Ewe', value: 'ee' },
        { name: 'Faroese', value: 'fo' },
        { name: 'Fijian', value: 'fj' },
        { name: 'Finnish', value: 'fi' },
        { name: 'French', value: 'fr' },
        { name: 'Western Frisian', value: 'fy' },
        { name: 'Fulah', value: 'ff' },
        { name: 'Gaelic, Scottish Gaelic', value: 'gd' },
        { name: 'Galician', value: 'gl' },
        { name: 'Ganda', value: 'lg' },
        { name: 'Georgian', value: 'ka' },
        { name: 'German', value: 'de' },
        { name: 'Greek, Modern (1453–)', value: 'el' },
        { name: 'Kalaallisut, Greenlandic', value: 'kl' },
        { name: 'Guarani', value: 'gn' },
        { name: 'Gujarati', value: 'gu' },
        { name: 'Haitian, Haitian Creole', value: 'ht' },
        { name: 'Hausa', value: 'ha' },
        { name: 'Hebrew', value: 'he' },
        { name: 'Herero', value: 'hz' },
        { name: 'Hindi', value: 'hi' },
        { name: 'Hiri Motu', value: 'ho' },
        { name: 'Hungarian', value: 'hu' },
        { name: 'Icelandic', value: 'is' },
        { name: 'Ido', value: 'io' },
        { name: 'Igbo', value: 'ig' },
        { name: 'Indonesian', value: 'id' },
        {
          name: 'Interlingua (International Auxiliary Language Association)',
          value: 'ia'
        },
        { name: 'Interlingue, Occidental', value: 'ie' },
        { name: 'Inuktitut', value: 'iu' },
        { name: 'Inupiaq', value: 'ik' },
        { name: 'Irish', value: 'ga' },
        { name: 'Italian', value: 'it' },
        { name: 'Japanese', value: 'ja' },
        { name: 'Javanese', value: 'jv' },
        { name: 'Kannada', value: 'kn' },
        { name: 'Kanuri', value: 'kr' },
        { name: 'Kashmiri', value: 'ks' },
        { name: 'Kazakh', value: 'kk' },
        { name: 'Central Khmer', value: 'km' },
        { name: 'Kikuyu, Gikuyu', value: 'ki' },
        { name: 'Kinyarwanda', value: 'rw' },
        { name: 'Kirghiz, Kyrgyz', value: 'ky' },
        { name: 'Komi', value: 'kv' },
        { name: 'Kongo', value: 'kg' },
        { name: 'Korean', value: 'ko' },
        { name: 'Kuanyama, Kwanyama', value: 'kj' },
        { name: 'Kurdish', value: 'ku' },
        { name: 'Lao', value: 'lo' },
        { name: 'Latin', value: 'la' },
        { name: 'Latvian', value: 'lv' },
        { name: 'Limburgan, Limburger, Limburgish', value: 'li' },
        { name: 'Lingala', value: 'ln' },
        { name: 'Lithuanian', value: 'lt' },
        { name: 'Luba-Katanga', value: 'lu' },
        { name: 'Luxembourgish, Letzeburgesch', value: 'lb' },
        { name: 'Macedonian', value: 'mk' },
        { name: 'Malagasy', value: 'mg' },
        { name: 'Malay', value: 'ms' },
        { name: 'Malayalam', value: 'ml' },
        { name: 'Maltese', value: 'mt' },
        { name: 'Manx', value: 'gv' },
        { name: 'Maori', value: 'mi' },
        { name: 'Marathi', value: 'mr' },
        { name: 'Marshallese', value: 'mh' },
        { name: 'Mongolian', value: 'mn' },
        { name: 'Nauru', value: 'na' },
        { name: 'Navajo, Navaho', value: 'nv' },
        { name: 'North Ndebele', value: 'nd' },
        { name: 'South Ndebele', value: 'nr' },
        { name: 'Ndonga', value: 'ng' },
        { name: 'Nepali', value: 'ne' },
        { name: 'Norwegian', value: 'no' },
        { name: 'Norwegian Bokmål', value: 'nb' },
        { name: 'Norwegian Nynorsk', value: 'nn' },
        { name: 'Sichuan Yi, Nuosu', value: 'ii' },
        { name: 'Occitan', value: 'oc' },
        { name: 'Ojibwa', value: 'oj' },
        { name: 'Oriya', value: 'or' },
        { name: 'Oromo', value: 'om' },
        { name: 'Ossetian, Ossetic', value: 'os' },
        { name: 'Pali', value: 'pi' },
        { name: 'Pashto, Pushto', value: 'ps' },
        { name: 'Persian', value: 'fa' },
        { name: 'Polish', value: 'pl' },
        { name: 'Portuguese', value: 'pt' },
        { name: 'Punjabi, Panjabi', value: 'pa' },
        { name: 'Quechua', value: 'qu' },
        { name: 'Romanian, Moldavian, Moldovan', value: 'ro' },
        { name: 'Romansh', value: 'rm' },
        { name: 'Rundi', value: 'rn' },
        { name: 'Russian', value: 'ru' },
        { name: 'Northern Sami', value: 'se' },
        { name: 'Samoan', value: 'sm' },
        { name: 'Sango', value: 'sg' },
        { name: 'Sanskrit', value: 'sa' },
        { name: 'Sardinian', value: 'sc' },
        { name: 'Serbian', value: 'sr' },
        { name: 'Shona', value: 'sn' },
        { name: 'Sindhi', value: 'sd' },
        { name: 'Sinhala, Sinhalese', value: 'si' },
        { name: 'Slovak', value: 'sk' },
        { name: 'Slovenian', value: 'sl' },
        { name: 'Somali', value: 'so' },
        { name: 'Southern Sotho', value: 'st' },
        { name: 'Spanish, Castilian', value: 'es' },
        { name: 'Sundanese', value: 'su' },
        { name: 'Swahili', value: 'sw' },
        { name: 'Swati', value: 'ss' },
        { name: 'Swedish', value: 'sv' },
        { name: 'Tagalog', value: 'tl' },
        { name: 'Tahitian', value: 'ty' },
        { name: 'Tajik', value: 'tg' },
        { name: 'Tamil', value: 'ta' },
        { name: 'Tatar', value: 'tt' },
        { name: 'Telugu', value: 'te' },
        { name: 'Thai', value: 'th' },
        { name: 'Tibetan', value: 'bo' },
        { name: 'Tigrinya', value: 'ti' },
        { name: 'Tonga (Tonga Islands)', value: 'to' },
        { name: 'Tsonga', value: 'ts' },
        { name: 'Tswana', value: 'tn' },
        { name: 'Turkish', value: 'tr' },
        { name: 'Turkmen', value: 'tk' },
        { name: 'Twi', value: 'tw' },
        { name: 'Uighur, Uyghur', value: 'ug' },
        { name: 'Ukrainian', value: 'uk' },
        { name: 'Urdu', value: 'ur' },
        { name: 'Uzbek', value: 'uz' },
        { name: 'Venda', value: 've' },
        { name: 'Vietnamese', value: 'vi' },
        { name: 'Volapük', value: 'vo' },
        { name: 'Walloon', value: 'wa' },
        { name: 'Welsh', value: 'cy' },
        { name: 'Wolof', value: 'wo' },
        { name: 'Xhosa', value: 'xh' },
        { name: 'Yiddish', value: 'yi' },
        { name: 'Yoruba', value: 'yo' },
        { name: 'Zhuang, Chuang', value: 'za' },
        { name: 'Zulu', value: 'zu' }
      ],
      closedCampaignYearTab: 0,
      // Campaign Overlay Properties
      logosOverlayThemeImages: ['theme-purple.png', 'theme-black.png', 'theme-light.png', 'theme-hype.png'],
      logosProgressBarImages: ['progress-blue.png', 'progress-green.png', 'progress-orange.png', 'progress-pink.png', 'progress-purple.png', 'progress-white.png'],
      logosCampaign: null,
      logosDialog: false,
      logosSuccess: false,
      logosError: false,
      logosOverlayThemeLabel: null,
      logosOverlayThemeLightLabel: null,
      logosOverlayThemeImageUrl: null,
      logosOverlayThemeLightImageUrl: null,
      logosOverlayMessageText: null,
      logosOverlayMessageInterval: 5,
      logosOverlayMessageDuration: 1,
      logosOverlayMessageStartDate: Date.now(),
      logosOverlayMessageStartTime: null,
      logosOverlayMessageEndDate: Date.now(),
      logosOverlayMessageEndTime: null,
      logosProgressBarImageUrl: null,
      logosProgressBarLightImageUrl: null,
      logosLogoImageUrl: null,
      logosLogoLightImageUrl: null,
      logosSponsorImageUrl: null,
      logosSponsorLightImageUrl: null,
      logosSponsorText: null,
      fileStackFileTypes: {
        campaignLogo: null,
        campaignLightLogo: null,
        sponsorLogo: null,
        sponsorLightLogo: null
      },
      fileStackFiles: [],
      sponsorCampaign: null,
      sponsorDialog: false,
      sponsorSuccess: false,
      sponsorError: false,
      sponsorLevel1Name: null,
      sponsorLevel1ImageUrl: null,
      sponsorLevel2Name: null,
      sponsorLevel2ImageUrl: null,
      sponsorLevel3Name: null,
      sponsorLevel3ImageUrl: null,
      sponsorLevel4Name: null,
      sponsorLevel4ImageUrl: null,
      sponsorLevel5Name: null,
      sponsorLevel5ImageUrl: null,
      sponsorLevel6Name: null,
      sponsorLevel6ImageUrl: null,
      sponsorLevel7Name: null,
      sponsorLevel7ImageUrl: null,
      sponsorLevel8Name: null,
      sponsorLevel8ImageUrl: null,
      sponsorLevel9Name: null,
      sponsorLevel9ImageUrl: null,
      sponsorLevel10Name: null,
      sponsorLevel10ImageUrl: null,
      sponsorLevel11Name: null,
      sponsorLevel11ImageUrl: null,
      sponsorLevel12Name: null,
      sponsorLevel12ImageUrl: null,
      fileStackSponsorTiers: {
        tier1: null,
        tier2: null,
        tier3: null,
        tier4: null,
        tier5: null,
        tier6: null,
        tier7: null,
        tier8: null,
        tier9: null,
        tier10: null,
        tier11: null,
        tier12: null
      },
      fileStackSponsorFiles: [],
      score: null,
      openCampaign: null,
      toggleOpen: true,
      year: Number(new Date().getFullYear()),
      screenHeight: 0,
      screenWidth: 0,
      showIndex: -1,
      editCampaign: null,
      editDialog: false,
      landingCampaign: null,
      landingDialog: false,
      landingSuccess: false,
      landingError: false,
      landingCustomSubtitle: null,
      landingDates: null,
      landingFaqsTitle: null,
      landingMultipleChoicesTitle: null,
      landingSignUpEnabled: false,
      landingBannerImageUrl: null,
      landingPrize1ImageUrl: null,
      landingPrize2ImageUrl: null,
      landingPrize3ImageUrl: null,
      landingPrize1Name: null,
      landingPrize2Name: null,
      landingPrize3Name: null,
      landingPrize4Name: null,
      landingPrize5Name: null,
      landingPrize6Name: null,
      landingPrize7Name: null,
      landingPrize8Name: null,
      landingPrize9Name: null,
      landingPrize10Name: null,
      landingPrize11Name: null,
      landingPrize12Name: null,
      landingCarouselInterval: 6,
      fileStackLandingImages: {
        banner: null,
        prize1: null,
        prize2: null,
        prize3: null
      },
      fileStackLandingFiles: [],
      closedPage: 1,
      closedPerPage: 7,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },
  watch: {
    includeRaids(newValue) {
      this.includeSubs = !newValue
      this.includeBits = !newValue
    },
    year(newValue) {
      this.closedPage = 1
    }
  },
  computed: {
    ...mapGetters(['user', 'scores', 'openCampaigns', 'closedCampaigns', 'searchResults', 'staff', 'timezones', 'tags']),
    closedCampaignYearFilter() {
      if (this.closedCampaigns.length) {
        const closed = this.closedCampaigns.filter((x) => x.year === this.closedCampaignYears[this.closedCampaignYearTab])
        if (this.showCountry) {
          return closed.filter((x) => x.country === this.showCountry)
        }
        return closed
      }
      return []
    },
    campaignYears() {
      const years = []
      this.closedCampaigns.forEach((campaign) => {
        if (!years.includes(campaign.year)) {
          years.push(campaign.year)
        }
      })
      return sortBy(years, 'desc')
    },
    closedCampaignYears() {
      const years = this.closedCampaigns.length ? this.closedCampaigns.map((item) => item.year).filter((value, index, self) => self.indexOf(value) === index) : []
      return sortBy(years)
    },
    filteredClosedCampaigns() {
      const closed = this.closedCampaigns?.length ? this.closedCampaigns.filter((item) => item.year === this.year && this.campaignExpired(item.end_date)) : []
      const sorted = sortBy(closed, ['end_date'], ['desc'])
      sorted.reverse()
      return sorted.slice((this.closedPage - 1) * this.closedPerPage, this.closedPage * this.closedPerPage) ?? 0
    },
    numberOfPages() {
      const closed = this.closedCampaigns?.length ? this.closedCampaigns.filter((item) => item.year === this.year && this.campaignExpired(item.end_date)) : []
      return closed.length < this.closedPerPage ? 1 : Math.ceil(closed.length / this.closedPerPage)
    },
    messageStarted() {
      return new Date() > new Date(this.logosCampaign.overlay_message_start_date)
    },
    messageExpired() {
      return new Date() > new Date(this.logosCampaign.overlay_message_end_date)
    },
    openCampaignsHeight() {
      const size = this.openCampaigns.length < 5 ? 5 : this.openCampaigns.length
      return size * 150
    }
  },
  methods: {
    ...mapActions(['loadSystem', 'createCampaignCoupons', 'createCampaign', 'addChannel', 'addChannels', 'deleteChannelScore', 'deleteCampaign', 'search', 'signOut', 'updateBonusExp', 'updateCampaignLogos', 'updateCampaignLanding', 'updateCampaignEdit']),
    updateBonusScore(score) {
      this.updateBonusExp(score)
    },
    deleteCampaignConfirm(campaign) {
      if (confirm('Are you sure you want to delete this campaign?')) {
        this.deleteCampaign(campaign)
        this.setShowIndex(-1)
      }
    },
    getTag(id) {
      const filtered = this.tags.filter((x) => x.id === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return ''
    },
    getLanguage(id) {
      const filtered = this.languages.filter((x) => x.value === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return this.getTag(id)
    },
    getRaidTarget(id) {
      const filtered = this.raidLevels.filter((x) => x.value === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return ''
    },
    browserSourceUrl(score, theme) {
      // return `${location.origin}/overlay.html?${score.browser_source_slug}/${this.user?.id}/${score?.campaign?.id}/${theme}`
      return `${location.origin}/overlay/${score.browser_source_slug}/${this.user?.id}/${score?.campaign?.id}/${theme}`
    },
    logout() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    getCompletedTier(score, tiers) {
      var tierIndex = -1
      for (let i = 0; i < tiers.length; i++) {
        if (tiers[i] <= score) {
          tierIndex = i
        }
      }
      if (tierIndex === -1) return 'N/A'
      return `${tierIndex + 1}`
    },
    getTargetScore(score, tiers) {
      for (let i = 0; i < tiers.length; i++) {
        if (score < tiers[i]) {
          return `${tiers[i]}`
        }
      }
      return 'N/A'
    },
    doSearch() {
      this.search(this.newChannelSearch)
    },
    cancel() {
      this.overlay = false
      this.startDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      this.endDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      this.startDateMenu = false
      this.endDateMenu = false
      this.startTimeMenu = false
      this.endTimeMenu = false
      this.startTime = null
      this.endTime = null
      this.name = ''
      this.localName = ''
      this.includeSubs = true
      this.includeBits = true
      this.includeRaids = false
      this.tier1 = 0
      this.tier2 = 0
      this.tier3 = 0
      this.tier4 = 0
      this.tier5 = 0
      this.tier6 = 0
      this.tier7 = 0
      this.tier8 = 0
      this.tier9 = 0
      this.tier10 = 0
      this.tier11 = 0
      this.tier12 = 0
      this.detailsUrl = null
      this.landingPageEnabled = false
      this.country = null
      this.campaign_raid_limit = 1
      this.daily_raid_limit = 1
      this.points_per_raider = 1
      this.link1Name = null
      this.link1Url = null
      this.link2Name = null
      this.link2Url = null
      this.link3Name = null
      this.link4Url = null
    },
    viewUserMgmt() {
      this.$router.push({
        name: 'userMgmt'
      })
    },
    viewCampaign(campaign) {
      this.$router.push({
        name: 'campaign',
        params: { campaignId: campaign.id }
      })
    },
    viewRewards(campaign) {
      this.$router.push({
        name: 'rewards',
        params: { campaignId: campaign.id }
      })
    },
    cancelNewChannel() {
      this.newChannelDialog = false
      this.newChannelCampaign = null
    },
    cancelViewChannels() {
      this.viewChannelsDialog = false
      this.viewChannelsCampaign = null
    },
    viewFaqs(campaign) {
      this.$router.push({
        name: 'faqs',
        params: { campaignId: campaign.id }
      })
    },
    viewMultipleChoices(campaign) {
      this.$router.push({
        name: 'multipleChoices',
        params: { campaignId: campaign.id }
      })
    },
    viewWhiteListUsers(campaign) {
      this.$router.push({
        name: 'whiteList',
        params: { campaignId: campaign.id }
      })
    },
    viewWaitListUsers(campaign) {
      this.$router.push({
        name: 'waitList',
        params: { campaignId: campaign.id }
      })
    },
    viewLanding(campaign) {
      const routeData = this.$router.resolve({ name: 'campaign', params: { slug: campaign.slug } })
      window.open(routeData.href, '_blank')
    },
    viewChannels(campaign) {
      this.$router.push({
        name: 'channels',
        params: { campaignId: campaign.id }
      })
    },
    viewCoupons(campaign) {
      this.$router.push({
        name: 'coupons',
        params: { campaignId: campaign.id }
      })
    },
    uploadCoupons(campaign) {
      this.couponDialog = true
      this.couponCampaign = campaign
    },
    cancelUploadCoupons() {
      this.couponDialog = false
      this.couponCampaign = null
      this.couponFile = ''
      this.couponFileName = ''
      this.couponFileContent = null
      this.couponFileParsed = false
    },
    handleUploadCoupons(file) {
      this.couponFile = file
      this.couponFileName = file.name
      Papa.parse(this.couponFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (content) {
          this.couponFileContent = content
          this.couponFileParsed = true
        }.bind(this)
      })
    },
    doImportCoupons() {
      if (this.couponFile) {
        this.couponSuccess = false
        this.couponError = false
        this.isLoading = true
        this.couponDialog = false
        if (this.couponFileParsed) {
          const data = this.couponFileContent.data
          const uniqueCodes = uniqBy(data, (obj) => obj.CouponCode)
          const uniqueUsers = uniqBy(data, (obj) => obj.UserId)
          const uniqueTiers = uniqBy(data, (obj) => obj.Tier)
          const couponsPerTiers = groupBy(data, 'Tier')
          this.couponImportCount = data.length
          this.couponCodesImportCount = uniqueCodes.length
          this.couponUsersImportCount = uniqueUsers.length
          this.couponTiersImport = uniqueTiers
          this.couponPerTiersImport = toArray(couponsPerTiers)
          this.couponLiveImportCount = data.filter((d) => d.Send === 'Live').length
          this.couponPostImportCount = data.filter((d) => d.Send === 'Post').length

          this.createCampaignCoupons({
            campaignId: this.couponCampaign.id,
            fileName: this.couponFileName,
            content: this.couponFileContent
          })
            .then((_) => {
              this.couponImportDialog = true
              this.couponSuccess = true
            })
            .catch((_) => {
              this.couponError = true
            })
            .finally((_) => {
              this.cancelUploadCoupons()
              this.isLoading = false
            })
        } else {
          this.couponError = true
        }
      }
    },
    newCampaign() {
      this.cancel()
      this.overlay = true
    },
    newChannel(campaign) {
      this.newChannelCampaign = campaign
      this.newChannelDialog = true
    },
    checkTiers() {
      if (this.tier1 > 2147483646) return false
      if (this.tier2 > 2147483646) return false
      if (this.tier3 > 2147483646) return false
      if (this.tier4 > 2147483646) return false
      if (this.tier5 > 2147483646) return false
      if (this.tier6 > 2147483646) return false
      if (this.tier7 > 2147483646) return false
      if (this.tier8 > 2147483646) return false
      if (this.tier9 > 2147483646) return false
      if (this.tier10 > 2147483646) return false
      if (this.tier11 > 2147483646) return false
      if (this.tier12 > 2147483646) return false
      return true
    },
    checkDates() {
      return !isEmpty(this.startDate) && !isEmpty(this.startTime) && !isEmpty(this.endDate) && !isEmpty(this.endTime)
    },
    create() {
      if (!this.checkTiers()) return
      if (!this.checkDates()) return
      this.createCampaign({
        name: this.name,
        local_name: this.localName,
        start_date: new Date(`${this.startDate} ${this.startTime}`),
        end_date: new Date(`${this.endDate} ${this.endTime}`),
        include_subs: this.includeSubs,
        include_bits: this.includeBits,
        include_raids: this.includeRaids,
        raid_timezone: this.includeRaids ? this.raidTimezone : '',
        raid_tag: this.includeRaids ? this.raidTag : '',
        tiers: [this.tier1, this.tier2, this.tier3, this.tier4, this.tier5, this.tier6, this.tier7, this.tier8, this.tier9, this.tier10, this.tier11, this.tier12],
        details_url: this.detailsUrl,
        landing_page_enabled: this.landingPageEnabled,
        link1_name: this.link1Name,
        link1_url: this.link1Url,
        link2_name: this.link2Name,
        link2_url: this.link2Url,
        link3_name: this.link3Name,
        link3_url: this.link3Url,
        country: this.country,
        is_test: false,
        campaign_raid_limit: this.campaign_raid_limit,
        daily_raid_limit: this.daily_raid_limit,
        points_per_raider: this.points_per_raider
      }).then((_) => {
        this.cancel()
      })
    },
    createTestCampaign() {
      if (!this.checkTiers()) return
      if (!this.checkDates()) return
      this.createCampaign({
        name: this.name,
        local_name: this.localName,
        start_date: new Date(`${this.startDate} ${this.startTime}`),
        end_date: new Date(`${this.endDate} ${this.endTime}`),
        include_subs: this.includeSubs,
        include_bits: this.includeBits,
        include_raids: this.includeRaids,
        raid_timezone: this.raidTimezone,
        raid_tag: this.raidTag,
        tiers: [this.tier1, this.tier2, this.tier3, this.tier4, this.tier5, this.tier6, this.tier7, this.tier8, this.tier9, this.tier10, this.tier11, this.tier12],
        details_url: this.detailsUrl,
        landing_page_enabled: this.landingPageEnabled,
        country: this.country,
        is_test: true,
        campaign_raid_limit: this.campaign_raid_limit,
        daily_raid_limit: this.daily_raid_limit,
        points_per_raider: this.points_per_raider
      }).then((_) => {
        this.cancel()
      })
    },
    addNewChannel(channelId) {
      this.newChannelDialog = false
      this.addChannel({
        campaignId: this.newChannelCampaign.id,
        channelId: channelId
      })
      this.newChannelCampaign = null
    },
    doImport() {
      this.importResult = []
      this.importDialog = false
      const finalResult = []
      const result = this.importText.split(/\r?\n/)
      result.forEach((element) => {
        element.split(',').forEach((sub) => {
          finalResult.push(sub.trim())
        })
      })
      const channels = finalResult.filter((str) => str !== '')
      this.newChannelDialog = false
      this.importCount = channels.length
      this.addChannels({
        campaignId: this.newChannelCampaign.id,
        channels: channels
      }).then((result) => {
        if (result.users_imported.length > 0) {
          const partners = result.users_imported.filter((user) => user.broadcaster_type === 'partner')
          const affiliates = result.users_imported.filter((user) => user.broadcaster_type === 'affiliate')
          this.importPartnersCount = partners.length
          this.importAffiliatesCount = affiliates.length
        } else {
          this.importPartnersCount = 0
          this.importAffiliatesCount = 0
        }

        this.importResult = result
        this.importDialog = true
      })
      this.newChannelCampaign = null
      this.importText = ''
    },
    getDate(dateTimeStr) {
      const date = dateTimeStr.split('T')[0]
      const now = new Date()
      const nowDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
      const localDate = new Date(dateTimeStr)
      const localDateStr = `${localDate.getFullYear()}-${String(localDate.getMonth() + 1).padStart(2, '0')}-${String(localDate.getDate()).padStart(2, '0')}`
      return date === '0001-01-01' || localDateStr === '0001-01-01' ? nowDate : localDateStr
    },
    getTime(dateTimeStr) {
      const time = dateTimeStr.split('T')[1]
      const localTime = new Date(dateTimeStr)
      const localTimeStr = `${String(localTime.getHours()).padStart(2, '0')}:${String(localTime.getMinutes()).padStart(2, '0')}`
      return time.includes('00:00') ? '00:00' : localTimeStr
    },
    updateLogos(campaign) {
      this.logosDialog = true
      this.logosCampaign = campaign
      this.logosOverlayThemeLabel = campaign.overlay_theme_label
      this.logosOverlayThemeLightLabel = campaign.overlay_theme_light_label
      this.logosOverlayThemeImageUrl = campaign.overlay_theme_image_url
      this.logosOverlayThemeLightImageUrl = campaign.overlay_theme_light_image_url
      this.logosOverlayMessageText = campaign.overlay_message_text
      this.logosOverlayMessageInterval = campaign.overlay_message_interval || 5
      this.logosOverlayMessageDuration = campaign.overlay_message_duration || 1
      this.logosOverlayMessageStartDate = campaign.overlay_message_start_date !== null ? this.getDate(campaign.overlay_message_start_date) : Date.now()
      this.logosOverlayMessageEndDate = campaign.overlay_message_end_date !== null ? this.getDate(campaign.overlay_message_end_date) : Date.now()
      this.logosOverlayMessageStartTime = campaign.overlay_message_start_date !== null ? this.getTime(campaign.overlay_message_start_date) : '00:00'
      this.logosOverlayMessageEndTime = campaign.overlay_message_end_date !== null ? this.getTime(campaign.overlay_message_end_date) : '00:00'
      this.logosProgressBarImageUrl = campaign.progress_bar_image_url
      this.logosProgressBarLightImageUrl = campaign.progress_bar_light_image_url
      this.logosLogoImageUrl = campaign.logo_image_url
      this.logosLogoLightImageUrl = campaign.logo_light_image_url
      this.logosSponsorImageUrl = campaign.sponsor_image_url
      this.logosSponsorLightImageUrl = campaign.sponsor_light_image_url
      this.logosSponsorText = campaign.sponsor_text
      this.sponsorLevel1ImageUrl = campaign.sponsor_level1_image_url
      this.sponsorLevel1Name = campaign.sponsor_level1_name
      this.sponsorLevel2ImageUrl = campaign.sponsor_level2_image_url
      this.sponsorLevel2Name = campaign.sponsor_level2_name
      this.sponsorLevel3ImageUrl = campaign.sponsor_level3_image_url
      this.sponsorLevel3Name = campaign.sponsor_level3_name
      this.sponsorLevel4ImageUrl = campaign.sponsor_level4_image_url
      this.sponsorLevel4Name = campaign.sponsor_level4_name
      this.sponsorLevel5ImageUrl = campaign.sponsor_level5_image_url
      this.sponsorLevel5Name = campaign.sponsor_level5_name
      this.sponsorLevel6ImageUrl = campaign.sponsor_level6_image_url
      this.sponsorLevel6Name = campaign.sponsor_level6_name
      this.sponsorLevel7ImageUrl = campaign.sponsor_level7_image_url
      this.sponsorLevel7Name = campaign.sponsor_level7_name
      this.sponsorLevel8ImageUrl = campaign.sponsor_level8_image_url
      this.sponsorLevel8Name = campaign.sponsor_level8_name
      this.sponsorLevel9ImageUrl = campaign.sponsor_level9_image_url
      this.sponsorLevel9Name = campaign.sponsor_level9_name
      this.sponsorLevel10ImageUrl = campaign.sponsor_level10_image_url
      this.sponsorLevel10Name = campaign.sponsor_level10_name
      this.sponsorLevel11ImageUrl = campaign.sponsor_level11_image_url
      this.sponsorLevel11Name = campaign.sponsor_level11_name
      this.sponsorLevel12ImageUrl = campaign.sponsor_level12_image_url
      this.sponsorLevel12Name = campaign.sponsor_level12_name
    },
    cancelUpdateLogos() {
      this.logosDialog = false
      this.logosCampaign = null
      this.logosOverlayThemeLabel = null
      this.logosOverlayThemeLightLabel = null
      this.logosOverlayThemeImageUrl = null
      this.logosOverlayThemeLightImageUrl = null
      this.logosOverlayMessageText = null
      this.logosOverlayMessageInterval = 5
      this.logosOverlayMessageDuration = 1
      this.logosOverlayMessageStartDate = null
      this.logosOverlayMessageEndDate = null
      this.logosOverlayMessageStartTime = null
      this.logosOverlayMessageEndTime = null
      this.logosProgressBarImageUrl = null
      this.logosProgressBarLightImageUrl = null
      this.logosLogoImageUrl = null
      this.logosLogoLightImageUrl = null
      this.logosSponsorImageUrl = null
      this.logosSponsorLightImageUrl = null
      this.logosSponsorText = null
      this.fileStackFileTypes = {
        campaignLogo: null,
        campaignLightLogo: null,
        sponsorLogo: null,
        sponsorLightLogo: null
      }
      this.fileStackFiles = []
      this.sponsorLevel1ImageUrl = null
      this.sponsorLevel1Name = null
      this.sponsorLevel2ImageUrl = null
      this.sponsorLevel2Name = null
      this.sponsorLevel3ImageUrl = null
      this.sponsorLevel3Name = null
      this.sponsorLevel4ImageUrl = null
      this.sponsorLevel4Name = null
      this.sponsorLevel5ImageUrl = null
      this.sponsorLevel5Name = null
      this.sponsorLevel6ImageUrl = null
      this.sponsorLevel6Name = null
      this.sponsorLevel7ImageUrl = null
      this.sponsorLevel7Name = null
      this.sponsorLevel8ImageUrl = null
      this.sponsorLevel8Name = null
      this.sponsorLevel9ImageUrl = null
      this.sponsorLevel9Name = null
      this.sponsorLevel10ImageUrl = null
      this.sponsorLevel10Name = null
      this.sponsorLevel11ImageUrl = null
      this.sponsorLevel11Name = null
      this.sponsorLevel12ImageUrl = null
      this.sponsorLevel12Name = null
      this.fileStackSponsorTiers = {
        tier1: null,
        tier2: null,
        tier3: null,
        tier4: null,
        tier5: null,
        tier6: null,
        tier7: null,
        tier8: null,
        tier9: null,
        tier10: null,
        tier11: null,
        tier12: null
      }
      this.fileStackSponsorFiles = []
    },
    resetUpdateLogos() {
      this.isLoading = true
      this.logosSuccess = false
      this.logosError = false
      this.logosDialog = false
      this.updateCampaignLogos({
        campaignId: this.logosCampaign.id,
        overlayThemeLabel: 'Dark',
        overlayThemeLightLabel: 'Light',
        overlayThemeImageUrl: null,
        overlayThemeLightImageUrl: null,
        overlayMessageText: null,
        overlayMessageInterval: 5,
        overlayMessageDuration: 1,
        overlayMessageStartDate: new Date(),
        overlayMessageEndDate: new Date(),
        progressBarImageUrl: null,
        progressBarLightImageUrl: null,
        logoImageUrl: null,
        logoLightImageUrl: null,
        sponsorImageUrl: null,
        sponsorLightImageUrl: null,
        sponsorText: null,
        sponsorLevel1ImageUrl: this.sponsorLevel1ImageUrl,
        sponsorLevel1Name: this.sponsorLevel1Name,
        sponsorLevel2ImageUrl: this.sponsorLevel2ImageUrl,
        sponsorLevel2Name: this.sponsorLevel2Name,
        sponsorLevel3ImageUrl: this.sponsorLevel3ImageUrl,
        sponsorLevel3Name: this.sponsorLevel3Name,
        sponsorLevel4ImageUrl: this.sponsorLevel4ImageUrl,
        sponsorLevel4Name: this.sponsorLevel4Name,
        sponsorLevel5ImageUrl: this.sponsorLevel5ImageUrl,
        sponsorLevel5Name: this.sponsorLevel5Name,
        sponsorLevel6ImageUrl: this.sponsorLevel6ImageUrl,
        sponsorLevel6Name: this.sponsorLevel6Name,
        sponsorLevel7ImageUrl: this.sponsorLevel7ImageUrl,
        sponsorLevel7Name: this.sponsorLevel7Name,
        sponsorLevel8ImageUrl: this.sponsorLevel8ImageUrl,
        sponsorLevel8Name: this.sponsorLevel8Name,
        sponsorLevel9ImageUrl: this.sponsorLevel9ImageUrl,
        sponsorLevel9Name: this.sponsorLevel9Name,
        sponsorLevel10ImageUrl: this.sponsorLevel10ImageUrl,
        sponsorLevel10Name: this.sponsorLevel10Name,
        sponsorLevel11ImageUrl: this.sponsorLevel11ImageUrl,
        sponsorLevel11Name: this.sponsorLevel11Name,
        sponsorLevel12ImageUrl: this.sponsorLevel12ImageUrl,
        sponsorLevel12Name: this.sponsorLevel12Name
      })
        .then((_) => {
          this.logosSuccess = true
        })
        .catch((_) => {
          this.logosError = true
        })
        .finally((_) => {
          this.cancelUpdateLogos()
          this.isLoading = false
        })
    },
    doUpdateLogos() {
      this.isLoading = true
      this.logosSuccess = false
      this.logosError = false
      this.logosDialog = false
      this.updateCampaignLogos({
        campaignId: this.logosCampaign.id,
        overlayThemeLabel: this.logosOverlayThemeLabel,
        overlayThemeLightLabel: this.logosOverlayThemeLightLabel,
        overlayThemeImageUrl: this.logosOverlayThemeImageUrl,
        overlayThemeLightImageUrl: this.logosOverlayThemeLightImageUrl,
        overlayMessageText: this.logosOverlayMessageText,
        overlayMessageInterval: this.logosOverlayMessageInterval,
        overlayMessageDuration: this.logosOverlayMessageDuration,
        overlayMessageStartDate: new Date(`${this.logosOverlayMessageStartDate} ${this.logosOverlayMessageStartTime}`),
        overlayMessageEndDate: new Date(`${this.logosOverlayMessageEndDate} ${this.logosOverlayMessageEndTime}`),
        progressBarImageUrl: this.logosProgressBarImageUrl,
        progressBarLightImageUrl: this.logosProgressBarLightImageUrl,
        logoImageUrl: this.logosLogoImageUrl,
        logoLightImageUrl: this.logosLogoLightImageUrl,
        sponsorImageUrl: this.logosSponsorImageUrl,
        sponsorLightImageUrl: this.logosSponsorLightImageUrl,
        sponsorText: this.logosSponsorText,
        sponsorLevel1ImageUrl: this.sponsorLevel1ImageUrl,
        sponsorLevel1Name: this.sponsorLevel1Name,
        sponsorLevel2ImageUrl: this.sponsorLevel2ImageUrl,
        sponsorLevel2Name: this.sponsorLevel2Name,
        sponsorLevel3ImageUrl: this.sponsorLevel3ImageUrl,
        sponsorLevel3Name: this.sponsorLevel3Name,
        sponsorLevel4ImageUrl: this.sponsorLevel4ImageUrl,
        sponsorLevel4Name: this.sponsorLevel4Name,
        sponsorLevel5ImageUrl: this.sponsorLevel5ImageUrl,
        sponsorLevel5Name: this.sponsorLevel5Name,
        sponsorLevel6ImageUrl: this.sponsorLevel6ImageUrl,
        sponsorLevel6Name: this.sponsorLevel6Name,
        sponsorLevel7ImageUrl: this.sponsorLevel7ImageUrl,
        sponsorLevel7Name: this.sponsorLevel7Name,
        sponsorLevel8ImageUrl: this.sponsorLevel8ImageUrl,
        sponsorLevel8Name: this.sponsorLevel8Name,
        sponsorLevel9ImageUrl: this.sponsorLevel9ImageUrl,
        sponsorLevel9Name: this.sponsorLevel9Name,
        sponsorLevel10ImageUrl: this.sponsorLevel10ImageUrl,
        sponsorLevel10Name: this.sponsorLevel10Name,
        sponsorLevel11ImageUrl: this.sponsorLevel11ImageUrl,
        sponsorLevel11Name: this.sponsorLevel11Name,
        sponsorLevel12ImageUrl: this.sponsorLevel12ImageUrl,
        sponsorLevel12Name: this.sponsorLevel12Name
      })
        .then((_) => {
          this.logosSuccess = true
        })
        .catch((_) => {
          this.logosError = true
        })
        .finally((_) => {
          this.cancelUpdateLogos()
          this.isLoading = false
        })
    },
    fileStackOnSelectFile(file, type) {
      if (this.fileStackFileTypes[type] === null) {
        if (file) {
          file.key = type
          this.fileStackFileTypes[type] = file.name
          this.fileStackFiles.push(file)
        }
      } else {
        this.fileStackFileTypes[type] = file
        const filteredFiles = this.fileStackFiles.filter((file) => file.key !== type)
        if (file) {
          file.key = type
          filteredFiles.push(file)
        }
        this.fileStackFiles = [].concat(filteredFiles)
      }
    },
    fileStackOnSuccess(result) {
      if (result.length > 0) {
        if (this.fileStackFileTypes.campaignLogo !== null) {
          const found = result.find((file) => file.name === this.fileStackFileTypes.campaignLogo)
          if (found) {
            this.logosLogoImageUrl = found.url
          }
        }
        if (this.fileStackFileTypes.campaignLightLogo !== null) {
          const found = result.find((file) => file.name === this.fileStackFileTypes.campaignLightLogo)
          if (found) {
            this.logosLogoLightImageUrl = found.url
          }
        }
        if (this.fileStackFileTypes.sponsorLogo !== null) {
          const found = result.find((file) => file.name === this.fileStackFileTypes.sponsorLogo)
          if (found) {
            this.logosSponsorImageUrl = found.url
          }
        }
        if (this.fileStackFileTypes.sponsorLightLogo !== null) {
          const found = result.find((file) => file.name === this.fileStackFileTypes.sponsorLightLogo)
          if (found) {
            this.logosSponsorLightImageUrl = found.url
          }
        }
      }
      this.doUpdateLogos()
    },
    fileStackOnError(error) {
      console.error(error)
      this.logosError = true
      this.cancelUpdateLogos()
      this.isLoading = false
    },
    updateSponsorLogos(campaign) {
      this.sponsorDialog = true
      this.sponsorCampaign = campaign
      this.logosOverlayThemeLabel = campaign.overlay_theme_label
      this.logosOverlayThemeLightLabel = campaign.overlay_theme_light_label
      this.logosOverlayThemeImageUrl = campaign.overlay_theme_image_url
      this.logosOverlayThemeLightImageUrl = campaign.overlay_theme_light_image_url
      this.logosOverlayMessageText = campaign.overlay_message_text
      this.logosOverlayMessageInterval = campaign.overlay_message_interval || 5
      this.logosOverlayMessageDuration = campaign.overlay_message_duration || 1
      this.logosOverlayMessageStartDate = campaign.overlay_message_start_date
      this.logosOverlayMessageEndDate = campaign.overlay_message_end_date
      this.logosProgressBarImageUrl = campaign.progress_bar_image_url
      this.logosProgressBarLightImageUrl = campaign.progress_bar_light_image_url
      this.logosLogoImageUrl = campaign.logo_image_url
      this.logosLogoLightImageUrl = campaign.logo_light_image_url
      this.logosSponsorImageUrl = campaign.sponsor_image_url
      this.logosSponsorLightImageUrl = campaign.sponsor_light_image_url
      this.logosSponsorText = campaign.sponsor_text
      this.sponsorLevel1ImageUrl = campaign.sponsor_level1_image_url
      this.sponsorLevel1Name = campaign.sponsor_level1_name
      this.sponsorLevel2ImageUrl = campaign.sponsor_level2_image_url
      this.sponsorLevel2Name = campaign.sponsor_level2_name
      this.sponsorLevel3ImageUrl = campaign.sponsor_level3_image_url
      this.sponsorLevel3Name = campaign.sponsor_level3_name
      this.sponsorLevel4ImageUrl = campaign.sponsor_level4_image_url
      this.sponsorLevel4Name = campaign.sponsor_level4_name
      this.sponsorLevel5ImageUrl = campaign.sponsor_level5_image_url
      this.sponsorLevel5Name = campaign.sponsor_level5_name
      this.sponsorLevel6ImageUrl = campaign.sponsor_level6_image_url
      this.sponsorLevel6Name = campaign.sponsor_level6_name
      this.sponsorLevel7ImageUrl = campaign.sponsor_level7_image_url
      this.sponsorLevel7Name = campaign.sponsor_level7_name
      this.sponsorLevel8ImageUrl = campaign.sponsor_level8_image_url
      this.sponsorLevel8Name = campaign.sponsor_level8_name
      this.sponsorLevel9ImageUrl = campaign.sponsor_level9_image_url
      this.sponsorLevel9Name = campaign.sponsor_level9_name
      this.sponsorLevel10ImageUrl = campaign.sponsor_level10_image_url
      this.sponsorLevel10Name = campaign.sponsor_level10_name
      this.sponsorLevel11ImageUrl = campaign.sponsor_level11_image_url
      this.sponsorLevel11Name = campaign.sponsor_level11_name
      this.sponsorLevel12ImageUrl = campaign.sponsor_level12_image_url
      this.sponsorLevel12Name = campaign.sponsor_level12_name
    },
    cancelUpdateSponsorLogos() {
      this.sponsorDialog = false
      this.sponsorCampaign = null
      this.logosOverlayThemeLabel = null
      this.logosOverlayThemeLightLabel = null
      this.logosOverlayThemeImageUrl = null
      this.logosOverlayThemeLightImageUrl = null
      this.logosOverlayMessageText = null
      this.logosOverlayMessageInterval = 5
      this.logosOverlayMessageDuration = 1
      this.logosOverlayMessageStartDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      this.logosOverlayMessageEndDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      this.logosOverlayMessageStartTime = null
      this.logosOverlayMessageEndTime = null
      this.logosProgressBarImageUrl = null
      this.logosProgressBarLightImageUrl = null
      this.logosLogoImageUrl = null
      this.logosLogoLightImageUrl = null
      this.logosSponsorImageUrl = null
      this.logosSponsorLightImageUrl = null
      this.logosSponsorText = null
      this.fileStackFileTypes = {
        campaignLogo: null,
        campaignLightLogo: null,
        sponsorLogo: null,
        sponsorLightLogo: null
      }
      this.fileStackFiles = []
      this.sponsorLevel1ImageUrl = null
      this.sponsorLevel1Name = null
      this.sponsorLevel2ImageUrl = null
      this.sponsorLevel2Name = null
      this.sponsorLevel3ImageUrl = null
      this.sponsorLevel3Name = null
      this.sponsorLevel4ImageUrl = null
      this.sponsorLevel4Name = null
      this.sponsorLevel5ImageUrl = null
      this.sponsorLevel5Name = null
      this.sponsorLevel6ImageUrl = null
      this.sponsorLevel6Name = null
      this.sponsorLevel7ImageUrl = null
      this.sponsorLevel7Name = null
      this.sponsorLevel8ImageUrl = null
      this.sponsorLevel8Name = null
      this.sponsorLevel9ImageUrl = null
      this.sponsorLevel9Name = null
      this.sponsorLevel10ImageUrl = null
      this.sponsorLevel10Name = null
      this.sponsorLevel11ImageUrl = null
      this.sponsorLevel11Name = null
      this.sponsorLevel12ImageUrl = null
      this.sponsorLevel12Name = null
      this.fileStackSponsorTiers = {
        tier1: null,
        tier2: null,
        tier3: null,
        tier4: null,
        tier5: null,
        tier6: null,
        tier7: null,
        tier8: null,
        tier9: null,
        tier10: null,
        tier11: null,
        tier12: null
      }
      this.fileStackSponsorFiles = []
    },
    resetUpdateSponsorLogos() {
      this.isLoading = true
      this.sponsorSuccess = false
      this.sponsorError = false
      this.sponsorDialog = false
      this.updateCampaignLogos({
        campaignId: this.sponsorCampaign.id,
        overlayThemeLabel: this.logosOverlayThemeLabel,
        overlayThemeLightLabel: this.logosOverlayThemeLightLabel,
        overlayThemeImageUrl: this.logosOverlayThemeImageUrl,
        overlayThemeLightImageUrl: this.logosOverlayThemeLightImageUrl,
        overlayMessageText: this.logosOverlayMessageText,
        overlayMessageInterval: this.logosOverlayMessageInterval,
        overlayMessageDuration: this.logosOverlayMessageDuration,
        overlayMessageStartDate: this.logosOverlayMessageStartDate,
        overlayMessageEndDate: this.logosOverlayMessageEndDate,
        progressBarImageUrl: this.logosProgressBarImageUrl,
        progressBarLightImageUrl: this.logosProgressBarLightImageUrl,
        logoImageUrl: this.logosLogoImageUrl,
        logoLightImageUrl: this.logosLogoLightImageUrl,
        sponsorImageUrl: this.logosSponsorImageUrl,
        sponsorLightImageUrl: this.logosSponsorLightImageUrl,
        sponsorText: this.logosSponsorText,
        sponsorLevel1ImageUrl: null,
        sponsorLevel1Name: null,
        sponsorLevel2ImageUrl: null,
        sponsorLevel2Name: null,
        sponsorLevel3ImageUrl: null,
        sponsorLevel3Name: null,
        sponsorLevel4ImageUrl: null,
        sponsorLevel4Name: null,
        sponsorLevel5ImageUrl: null,
        sponsorLevel5Name: null,
        sponsorLevel6ImageUrl: null,
        sponsorLevel6Name: null,
        sponsorLevel7ImageUrl: null,
        sponsorLevel7Name: null,
        sponsorLevel8ImageUrl: null,
        sponsorLevel8Name: null,
        sponsorLevel9ImageUrl: null,
        sponsorLevel9Name: null,
        sponsorLevel10ImageUrl: null,
        sponsorLevel10Name: null,
        sponsorLevel11ImageUrl: null,
        sponsorLevel11Name: null,
        sponsorLevel12ImageUrl: null,
        sponsorLevel12Name: null
      })
        .then((_) => {
          this.sponsorSuccess = true
        })
        .catch((_) => {
          this.sponsorError = true
        })
        .finally((_) => {
          this.cancelUpdateSponsorLogos()
          this.isLoading = false
        })
    },
    doUpdateSponsorLogos() {
      this.isLoading = true
      this.sponsorSuccess = false
      this.sponsorError = false
      this.sponsorDialog = false
      this.updateCampaignLogos({
        campaignId: this.sponsorCampaign.id,
        overlayThemeLabel: this.logosOverlayThemeLabel,
        overlayThemeLightLabel: this.logosOverlayThemeLightLabel,
        overlayThemeImageUrl: this.logosOverlayThemeImageUrl,
        overlayThemeLightImageUrl: this.logosOverlayThemeLightImageUrl,
        overlayMessageText: this.logosOverlayMessageText,
        overlayMessageInterval: this.logosOverlayMessageInterval,
        overlayMessageDuration: this.logosOverlayMessageDuration,
        overlayMessageStartDate: this.logosOverlayMessageStartDate,
        overlayMessageEndDate: this.logosOverlayMessageEndDate,
        progressBarImageUrl: this.logosProgressBarImageUrl,
        progressBarLightImageUrl: this.logosProgressBarLightImageUrl,
        logoImageUrl: this.logosLogoImageUrl,
        logoLightImageUrl: this.logosLogoLightImageUrl,
        sponsorImageUrl: this.logosSponsorImageUrl,
        sponsorLightImageUrl: this.logosSponsorLightImageUrl,
        sponsorText: this.logosSponsorText,
        sponsorLevel1ImageUrl: this.sponsorLevel1ImageUrl,
        sponsorLevel1Name: this.sponsorLevel1Name,
        sponsorLevel2ImageUrl: this.sponsorLevel2ImageUrl,
        sponsorLevel2Name: this.sponsorLevel2Name,
        sponsorLevel3ImageUrl: this.sponsorLevel3ImageUrl,
        sponsorLevel3Name: this.sponsorLevel3Name,
        sponsorLevel4ImageUrl: this.sponsorLevel4ImageUrl,
        sponsorLevel4Name: this.sponsorLevel4Name,
        sponsorLevel5ImageUrl: this.sponsorLevel5ImageUrl,
        sponsorLevel5Name: this.sponsorLevel5Name,
        sponsorLevel6ImageUrl: this.sponsorLevel6ImageUrl,
        sponsorLevel6Name: this.sponsorLevel6Name,
        sponsorLevel7ImageUrl: this.sponsorLevel7ImageUrl,
        sponsorLevel7Name: this.sponsorLevel7Name,
        sponsorLevel8ImageUrl: this.sponsorLevel8ImageUrl,
        sponsorLevel8Name: this.sponsorLevel8Name,
        sponsorLevel9ImageUrl: this.sponsorLevel9ImageUrl,
        sponsorLevel9Name: this.sponsorLevel9Name,
        sponsorLevel10ImageUrl: this.sponsorLevel10ImageUrl,
        sponsorLevel10Name: this.sponsorLevel10Name,
        sponsorLevel11ImageUrl: this.sponsorLevel11ImageUrl,
        sponsorLevel11Name: this.sponsorLevel11Name,
        sponsorLevel12ImageUrl: this.sponsorLevel12ImageUrl,
        sponsorLevel12Name: this.sponsorLevel12Name
      })
        .then((_) => {
          this.sponsorSuccess = true
        })
        .catch((_) => {
          this.sponsorError = true
        })
        .finally((_) => {
          this.cancelUpdateSponsorLogos()
          this.isLoading = false
        })
    },
    fileStackSponsorOnSelectFile(file, type) {
      if (this.fileStackSponsorTiers[type] === null) {
        if (file) {
          file.key = type
          this.fileStackSponsorTiers[type] = file.name
          this.fileStackSponsorFiles.push(file)
        }
      } else {
        this.fileStackSponsorTiers[type] = file
        const filteredFiles = this.fileStackSponsorFiles.filter((file) => file.key !== type)
        if (file) {
          file.key = type
          filteredFiles.push(file)
        }
        this.fileStackSponsorFiles = [].concat(filteredFiles)
      }
    },
    fileStackSponsorOnSuccess(result) {
      if (result.length > 0) {
        if (this.fileStackSponsorTiers.tier1 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier1)
          if (found) {
            this.sponsorLevel1ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier2 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier2)
          if (found) {
            this.sponsorLevel2ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier3 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier3)
          if (found) {
            this.sponsorLevel3ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier4 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier4)
          if (found) {
            this.sponsorLevel4ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier5 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier5)
          if (found) {
            this.sponsorLevel5ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier6 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier6)
          if (found) {
            this.sponsorLevel6ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier7 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier7)
          if (found) {
            this.sponsorLevel7ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier8 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier8)
          if (found) {
            this.sponsorLevel8ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier9 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier9)
          if (found) {
            this.sponsorLevel9ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier10 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier10)
          if (found) {
            this.sponsorLevel10ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier11 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier11)
          if (found) {
            this.sponsorLevel11ImageUrl = found.url
          }
        }
        if (this.fileStackSponsorTiers.tier12 !== null) {
          const found = result.find((file) => file.name === this.fileStackSponsorTiers.tier12)
          if (found) {
            this.sponsorLevel12ImageUrl = found.url
          }
        }
      }
      this.doUpdateSponsorLogos()
    },
    fileStackSponsorOnError(error) {
      console.error(error)
      this.sponsorError = true
      this.cancelUpdateSponsorLogos()
      this.isLoading = false
    },
    updateEdit(campaign) {
      this.editDialog = true
      this.editCampaign = campaign
      this.name = campaign.name
      this.localName = campaign.local_name
      this.link1Name = campaign.link1_name
      this.link1Url = campaign.link1_url
      this.link2Name = campaign.link2_name
      this.link2Url = campaign.link2_url
      this.link3Name = campaign.link3_name
      this.link3Url = campaign.link3_url
    },
    cancelUpdateEdit() {
      this.editDialog = false
      this.editCampaign = null
      this.name = null
      this.localName = null
      this.link1Name = null
      this.link1Url = null
      this.link2Name = null
      this.link2Url = null
      this.link3Name = null
      this.link3Url = null
      this.showIndex = -1
    },
    doUpdateEdit() {
      this.isLoading = true
      this.editDialog = false
      this.success = false
      this.error = false
      this.updateCampaignEdit({
        campaignId: this.editCampaign.id,
        name: this.name,
        localName: this.localName,
        link1Name: this.link1Name,
        link1Url: this.link1Url,
        link2Name: this.link2Name,
        link2Url: this.link2Url,
        link3Name: this.link3Name,
        link3Url: this.link3Url
      })
        .then((_) => {
          this.success = true
        })
        .catch((_) => {
          this.error = true
        })
        .finally((_) => {
          this.cancelUpdateEdit()
          this.isLoading = false
        })
    },
    updateLanding(campaign) {
      this.landingDialog = true
      this.landingCampaign = campaign
      this.landingCustomSubtitle = campaign.landing_custom_subtitle
      this.landingDates = campaign.landing_dates
      this.landingFaqsTitle = campaign.landing_faqs_title
      this.landingMultipleChoicesTitle = campaign.landing_multiple_choices_title
      this.landingSignUpEnabled = campaign.landing_sign_up_enabled
      this.landingBannerImageUrl = campaign.landing_banner_image_url
      this.landingPrize1ImageUrl = campaign.landing_prize1_image_url
      this.landingPrize2ImageUrl = campaign.landing_prize2_image_url
      this.landingPrize3ImageUrl = campaign.landing_prize3_image_url
      this.landingPrize1Name = campaign.landing_prize1_name
      this.landingPrize2Name = campaign.landing_prize2_name
      this.landingPrize3Name = campaign.landing_prize3_name
      this.landingPrize4Name = campaign.landing_prize4_name
      this.landingPrize5Name = campaign.landing_prize5_name
      this.landingPrize6Name = campaign.landing_prize6_name
      this.landingPrize7Name = campaign.landing_prize7_name
      this.landingPrize8Name = campaign.landing_prize8_name
      this.landingPrize9Name = campaign.landing_prize9_name
      this.landingPrize10Name = campaign.landing_prize10_name
      this.landingPrize11Name = campaign.landing_prize11_name
      this.landingPrize12Name = campaign.landing_prize12_name
      this.landingCarouselInterval = campaign.landing_carousel_interval ?? 6
    },
    cancelUpdateLanding() {
      this.landingDialog = false
      this.landingCampaign = null
      this.landingCustomSubtitle = null
      this.landingDates = null
      this.landingFaqsTitle = null
      this.landingMultipleChoicesTitle = null
      this.landingSignUpEnabled = false
      this.landingBannerImageUrl = null
      this.landingPrize1ImageUrl = null
      this.landingPrize2ImageUrl = null
      this.landingPrize3ImageUrl = null
      this.landingPrize1Name = null
      this.landingPrize2Name = null
      this.landingPrize3Name = null
      this.landingPrize4Name = null
      this.landingPrize5Name = null
      this.landingPrize6Name = null
      this.landingPrize7Name = null
      this.landingPrize8Name = null
      this.landingPrize9Name = null
      this.landingPrize10Name = null
      this.landingPrize11Name = null
      this.landingPrize12Name = null
      this.landingCarouselInterval = 6
      this.fileStackLandingImages = {
        banner: null,
        prize1: null,
        prize2: null,
        prize3: null
      }
      this.fileStackLandingFiles = []
    },
    resetUpdateLanding() {
      this.isLoading = true
      this.landingSuccess = false
      this.landingError = false
      this.landingDialog = false
      this.updateCampaignLanding({
        campaignId: this.landingCampaign.id,
        landingCustomSubtitle: null,
        landingDates: null,
        landingFaqsTitle: null,
        landingMultipleChoicesTitle: null,
        landingSignUpEnabled: false,
        landingBannerImageUrl: null,
        landingPrize1ImageUrl: null,
        landingPrize2ImageUrl: null,
        landingPrize3ImageUrl: null,
        landingPrize1Name: null,
        landingPrize2Name: null,
        landingPrize3Name: null,
        landingPrize4Name: null,
        landingPrize5Name: null,
        landingPrize6Name: null,
        landingPrize7Name: null,
        landingPrize8Name: null,
        landingPrize9Name: null,
        landingPrize10Name: null,
        landingPrize11Name: null,
        landingPrize12Name: null,
        landingCarouselInterval: 6
      })
        .then((_) => {
          this.landingSuccess = true
        })
        .catch((_) => {
          this.landingError = true
        })
        .finally((_) => {
          this.cancelUpdateLanding()
          this.isLoading = false
        })
    },
    doUpdateLanding() {
      this.isLoading = true
      this.landingSuccess = false
      this.landingError = false
      this.landingDialog = false
      this.updateCampaignLanding({
        campaignId: this.landingCampaign.id,
        landingCustomSubtitle: this.landingCustomSubtitle,
        landingDates: this.landingDates,
        landingFaqsTitle: this.landingFaqsTitle,
        landingMultipleChoicesTitle: this.landingMultipleChoicesTitle,
        landingSignUpEnabled: this.landingSignUpEnabled,
        landingBannerImageUrl: this.landingBannerImageUrl,
        landingPrize1ImageUrl: this.landingPrize1ImageUrl,
        landingPrize2ImageUrl: this.landingPrize2ImageUrl,
        landingPrize3ImageUrl: this.landingPrize3ImageUrl,
        landingPrize1Name: this.landingPrize1Name,
        landingPrize2Name: this.landingPrize2Name,
        landingPrize3Name: this.landingPrize3Name,
        landingPrize4Name: this.landingPrize4Name,
        landingPrize5Name: this.landingPrize5Name,
        landingPrize6Name: this.landingPrize6Name,
        landingPrize7Name: this.landingPrize7Name,
        landingPrize8Name: this.landingPrize8Name,
        landingPrize9Name: this.landingPrize9Name,
        landingPrize10Name: this.landingPrize10Name,
        landingPrize11Name: this.landingPrize11Name,
        landingPrize12Name: this.landingPrize12Name,
        landingCarouselInterval: this.landingCarouselInterval
      })
        .then((_) => {
          this.landingSuccess = true
        })
        .catch((_) => {
          this.landingError = true
        })
        .finally((_) => {
          this.cancelUpdateLanding()
          this.isLoading = false
        })
    },
    fileStackLandingOnSelectFile(file, type) {
      if (this.fileStackLandingImages[type] === null) {
        if (file) {
          file.key = type
          this.fileStackLandingImages[type] = file.name
          this.fileStackLandingFiles.push(file)
        }
      } else {
        this.fileStackLandingImages[type] = file
        const filteredFiles = this.fileStackLandingFiles.filter((file) => file.key !== type)
        if (file) {
          file.key = type
          filteredFiles.push(file)
        }
        this.fileStackLandingFiles = [].concat(filteredFiles)
      }
    },
    fileStackLandingOnSuccess(result) {
      if (result.length > 0) {
        if (this.fileStackLandingImages.banner !== null) {
          const found = result.find((file) => file.name === this.fileStackLandingImages.banner)
          if (found) {
            this.landingBannerImageUrl = found.url
          }
        }
        if (this.fileStackLandingImages.prize1 !== null) {
          const found = result.find((file) => file.name === this.fileStackLandingImages.prize1)
          if (found) {
            this.landingPrize1ImageUrl = found.url
          }
        }
        if (this.fileStackLandingImages.prize2 !== null) {
          const found = result.find((file) => file.name === this.fileStackLandingImages.prize2)
          if (found) {
            this.landingPrize2ImageUrl = found.url
          }
        }
        if (this.fileStackLandingImages.prize3 !== null) {
          const found = result.find((file) => file.name === this.fileStackLandingImages.prize3)
          if (found) {
            this.landingPrize3ImageUrl = found.url
          }
        }
      }
      this.doUpdateLanding()
    },
    fileStackLandingOnError(error) {
      console.error(error)
      this.landingError = true
      this.cancelUpdateLanding()
      this.isLoading = false
    },
    toggle(scrollToTop) {
      this.toggleOpen = !this.toggleOpen
      if (scrollToTop) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    },
    filterByYear(year) {
      this.year = year
      this.toggle(true)
    },
    onScreenResize() {
      window.addEventListener('resize', () => {
        this.updateScreenHeight()
        this.updateScreenWidth()
      })
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    campaignStarted(startDate) {
      return new Date() > new Date(startDate)
    },
    campaignExpired(endDate) {
      return new Date() > new Date(endDate)
    },
    formattedStartDate(campaign) {
      const d = new Date(campaign.start_date)
      return this.months[d.getMonth()] + ' ' + d.getDate()
    },
    formattedEndDate(campaign) {
      const d = new Date(campaign.end_date)
      return this.months[d.getMonth()] + ' ' + d.getDate()
    },
    setData() {
      this.scores.forEach((score) => {
        if (!this.campaignExpired(score.campaign.end_date)) {
          this.score = score
          this.openCampaign = score.campaign
        }
      })
      if (this.openCampaign) {
        this.toggleOpen = true
      }
    },
    setShowIndex(index) {
      this.showIndex = index
    }
  },
  mounted() {
    this.isLoading = true
    this.updateScreenHeight()
    this.updateScreenWidth()
    this.onScreenResize()
    this.$vuetify.lang.current = 'en'
    if (this.user == null) {
      this.loadSystem().then((_) => {
        this.setData()
        this.isLoading = false
      })
    } else {
      this.setData()
      this.isLoading = false
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>

<style lang="scss" scoped>
.dashboard-creators-web,
.dashboard-creators-web * {
  box-sizing: border-box;
}
.dashboard-creators-web {
  background: #000000 !important;
  width: 100% !important;
  width: 1920px;
  height: 2292px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1080px) {
    height: 1000px;
  }
}
.bg-main {
  background: linear-gradient(180deg, #e3ff93 0%, #c1ff0c 19.27%);
  width: 100%;
  height: 1074px;
  position: absolute;
  left: 0.46px;
  top: -3px;
  @media only screen and (max-width: 1080px) {
    height: 1000px;
  }
}
.bg-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1103px;
  position: absolute;
  left: 0.46px;
  top: 305px;
  box-shadow: 0px -40px 44px 0px rgba(40, 48, 18, 0.25);
  @media only screen and (max-width: 1080px) {
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    height: 150px;
    position: absolute;
    left: 0px;
    top: 850px;
  }
}
.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-width: 1080px) {
    max-width: 900px;
  }
}
.welcome {
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 106px;
  position: absolute;
  left: calc(50% - 663.54px);
  top: 167.6px;
  z-index: 1;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.header {
  position: absolute;
  inset: 0;
}
.creator-photo {
  width: 42px;
  height: 42px;
  position: static;
  z-index: 1;
}
.photo {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 70px;
  top: 60px;
  z-index: 1;

  @media only screen and (max-width: 1080px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 44px;
    top: 44px;
    z-index: 1;
  }
}
.logout {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-width: 1080px) {
    z-index: 1;
  }
}

.user-mgmt {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  width: 72px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
  z-index: 10;
  cursor: pointer;
}
.creator-username {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  position: absolute;
  right: 130px;
  top: 63px;
  width: 372px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media only screen and (max-width: 1080px) {
    text-align: center;
    font: 600 14px/18px 'RoobertRegular', sans-serif;
    position: absolute;
    right: 70px;
    top: 64px;
    width: 124px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.twitch-logo {
  width: 131px;
  height: 44px;
  position: absolute;
  left: 99.46px;
  top: 61px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}
.campaign {
  position: absolute;
  inset: 0;
  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.scores-mobile {
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.scores-mobile-completed {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.scores-mobile-complete-level {
  width: 307px;
  height: 40px;
  position: static;
}
.scores-mobile-complete-box {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 1px;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 43px;
  top: 603px;
}
.scores-mobile-completed-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/27.35px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 609px;
  width: 155px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-completed-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 622px;
  width: 105px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-next-lvl {
  width: 307px;
  height: 40px;
  position: static;
}
.scores-mobile-next-box {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 1px;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 43px;
  top: 660px;
}
.scores-mobile-next-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/27.35px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 666px;
  width: 166px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-next-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 684px;
  width: 106px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores {
  width: 1338px;
  height: 124px;
  position: static;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.completed-lvl {
  width: 655px;
  height: 120px;
  position: static;
}
.completed-lvl-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 655px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 1105px;
}
.next-lvl-score {
  width: 644px;
  height: 120px;
  position: static;
}
.next-lvl-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 644px;
  height: 120px;
  position: absolute;
  left: calc(50% + 40px);
  top: 1109px;
}
.completed-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/27.35px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 1136px;
  width: 306px;
}
.next-level-score {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/27.35px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 90px);
  top: 1131px;
  width: 357px;
}
.completed-level-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 1164px;
  width: 475px;
}
.next-level-score-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 90px);
  top: 1159px;
  width: 475px;
}
.completed-level-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 655px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 1107px;
}
.next-level-score-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 644px;
  height: 120px;
  position: absolute;
  left: calc(50% + 40px);
  top: 1107px;
}
.current-score {
  width: 1338px;
  height: 120px;
  position: static;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.current-score-box {
  background: #beff00;
  border-radius: 128px;
  width: 1338px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 945px;

  @media only screen and (max-width: 1080px) {
    width: 307px;
    height: 40px;
    left: calc(50% - 151px);
    top: 545px;
  }
}
.current-score-value {
  color: #000000;
  text-align: right;
  font: 600 50px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 280px);
  top: 990px;
  width: 344px;
  height: 43px;

  @media only screen and (max-width: 1080px) {
    font: 600 14px/37.23px 'RoobertRegular', sans-serif;
    text-align: right;
    position: absolute;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    left: calc(50% - 35px);
    top: 542px;
  }
}
.current-score-label {
  color: #000000;
  text-align: left;
  font: 600 50px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 985px;
  width: 617px;
  height: 43px;

  @media only screen and (max-width: 1080px) {
    font: 600 14px/37.23px 'RoobertRegular', sans-serif;
    left: calc(50% - 135px);
    top: 545px;
  }
}
.campaign-details-mobile {
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
    box-sizing: border-box;
    position: absolute;
    inset: 0;
  }
}
.campaign-details-mobile-box {
  background: #111111;
  border-radius: 15px;
  width: 307px;
  height: 260px;
  position: absolute;
  left: 43px;
  top: 263px;
}
.campaign-details-mobile-content {
  width: 251px;
  height: 212px;
  position: static;
}
.campaign-details-mobile-start-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 286px;
  width: 110.62px;
}
.campaign-details-mobile-start-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 296px;
  width: 102px;
}
.campaign-details-mobile-end-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 344px;
  width: 100.62px;
}
.campaign-details-mobile-end-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 354px;
  width: 103px;
}
.campaign-details-mobile-time-left-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 402px;
  width: 120.62px;
}
.campaign-details-mobile-time-left-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 412px;
  width: 123px;
}
.campaign-details-mobile-includes-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 460px;
  width: 100.62px;
}
.campaign-details-mobile-includes-subs {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  left: 217px;
  top: 467px;
}
.campaign-details-mobile-includes-bits {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  left: 263px;
  top: 467px;
}
.campaign-details-mobile-includes-raids {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  right: 42px;
  top: 467px;
}
.campaign-details-mobile-includes-text {
  color: #beff00;
  text-align: center;
  font: 600 12px/37.23px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaign-details {
  width: 1335px;
  height: 361px;
  position: static;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.campaign-details-box {
  background: #111111;
  border-radius: 56px;
  width: 1335px;
  height: 361px;
  position: absolute;
  left: calc(50% - 660px);
  top: 544px;
}
.campaign-details-content {
  width: 984px;
  height: 258px;
  position: static;
}
.start-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 598px;
  width: 251px;
}
.start-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 598px;
  width: 401px;
}
.end-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 667px;
  width: 251px;
}
.end-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 667px;
  width: 401px;
}
.time-left {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 736px;
  width: 251px;
}
.time-left-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 736px;
  width: 472px;
}
.includes-text {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 805px;
  width: 251px;
}
.includes-subs {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 80px);
  top: calc(50% - 340px);
}
.includes-label {
  color: #beff00;
  text-align: right;
  font: 600 30px/37.23px 'RoobertRegular';
  position: relative;
}
.includes-bits {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 205px);
  top: calc(50% - 340px);
}
.includes-raids {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 315px);
  top: calc(50% - 340px);
}
.campaign-text {
  width: 468px;
  height: 38px;
  position: static;
}
.campaign-name-label {
  color: #f0f0ff;
  text-align: left;
  font: 700 60px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 661px);
  top: 423px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 12;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}

.campaign-name-label:hover {
  color: #beff00;
}
.rewards-button {
  background: #beff00;
  border-radius: 286.39px;
  padding: 14px 20px 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - -520px);
  top: calc(50% - 738px);
  z-index: 1;
  width: 155px;
  cursor: pointer;

  span > :last-child > :first-child {
    width: 40px !important;
    margin-left: -16px;
    top: -20px !important;
    background-color: #e91916 !important;
    border-color: #e91916 !important;
  }

  @media only screen and (max-width: 1080px) {
    padding: 1px 13px 1px 13px;
    display: flex;
    flex-direction: column;
    gap: 37.23px;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: absolute;
    left: calc(50% - 53.5px);
    top: calc(50% - 280px);
    width: 86px;

    span > :last-child > :first-child {
      width: 34px !important;
      margin-left: -14px;
      top: -10px !important;
      background-color: #e91916 !important;
      border-color: #e91916 !important;
    }
  }
}
.rewards-button:hover {
  background: #7d5bbe;
}
.rewards-badge {
  color: var(--black, #000000);
  font: 600 30px/37.23px 'RoobertRegular';
}
.button-label {
  color: var(--black-ops, #000000);
  text-align: right;
  font: 600 28px/37.23px 'RoobertRegular';
  position: relative;

  @media only screen and (max-width: 1080px) {
    font: 600 15px/37.23px 'RoobertRegular';
  }
}
.details-button {
  background: #beff00;
  border-radius: 286.39px;
  padding: 14px 20px 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - -350px);
  top: calc(50% - 738px);
  z-index: 1;
  width: 155px;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    padding: 1px 13px 1px 13px;
    display: flex;
    flex-direction: column;
    gap: 37.23px;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: absolute;
    left: calc(50% - 151px);
    top: calc(50% - 280px);
    width: 86px;
  }
}
.details-button:hover {
  background: #7d5bbe;
}
.details-link {
  text-decoration: none;
}
.url {
  width: 542.54px;
  height: 49px;
  position: static;
  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.url-mobile {
  width: 542.54px;
  height: 49px;
  position: static;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.url2 {
  width: 185px;
  height: 38px;
  position: static;
}
.light {
  width: 129px;
  height: 48px;
  position: static;
}
.theme-light {
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% + 546px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;
}
.theme-light:hover {
  background: #7d5bbe;
}
.theme-label {
  color: #000000;
  text-align: right;
  font: 500 30px/37.23px 'RoobertRegular';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
}
.copy {
  position: absolute;
  left: calc(50% - 54px);
  top: 10px;
  overflow: visible;
}
.dark {
  width: 129px;
  height: 48px;
  position: static;
}
.dark-mobile {
  width: 45px;
  height: 17px;
  position: static;
}
.buttons-mobile {
  background: #beff00;
  border-radius: 286.39px;
  padding: 1px 8px 1px 8px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 17px;
  position: absolute;
  left: 220px;
  top: 725px;
  z-index: 1;
}
.buttons2-mobile {
  background: #beff00;
  border-radius: 286.39px;
  padding: 1px 8px 1px 8px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 17px;
  position: absolute;
  left: 288px;
  top: 725px;
  z-index: 1;
}
.tab-name-mobile {
  color: #000000;
  text-align: right;
  font: 500 11px/37.23px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  z-index: 1;
  width: 60px;
}
.copy-mobile {
  position: absolute;
  left: 226px;
  top: 728px;
  overflow: visible;
  z-index: 1;
}
.copy2-mobile {
  position: absolute;
  left: 293px;
  top: 728px;
  overflow: visible;
  z-index: 1;
}
.theme-dark {
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% + 400px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;
}
.theme-dark:hover {
  background: #7d5bbe;
}
.copy2 {
  position: absolute;
  left: calc(50% - 50px);
  top: 10px;
  overflow: visible;
}
.overlay {
  color: rgba(240, 240, 255, 0.7);
  text-align: right;
  font: 500 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 246px);
  top: calc(50% + 168px);
  display: flex;
  justify-content: flex-end;
  width: 590px;
}
.overlay-mobile {
  color: rgba(240, 240, 255, 0.7);
  text-align: right;
  font: 500 11px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - -216px);
  display: flex;
  justify-content: flex-end;
  width: 160px;
}
.overlay-tooltip {
  z-index: 1;
  cursor: pointer;
}
.overlay-box {
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(240, 240, 255, 0.7);
  border-width: 2px;
  width: 25px;
  height: 25px;
  position: absolute;
  left: calc(50% + 356px);
  top: 1322px;
}
.overlay-question {
  color: rgba(240, 240, 255, 0.7);
  text-align: left;
  font: 500 18px/37.23px 'arial';
  position: absolute;
  left: calc(50% + 363px);
  top: 1316px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  cursor: pointer;
}
.bg-closed {
  background: #111111;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 379px;
  position: absolute;
  left: 0.46px;
  top: 1780px;
}
.bg-closed-mobile {
  background: #111111;
  border-radius: 30px 30px 0px 0px;
  width: 393px;
  height: 171px;
  position: absolute;
  left: 0px;
  bottom: 70px;
  visibility: hidden;

  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.bg-closed-mobile-box {
  background: #000000;
  width: 393px;
  height: 168px;
  position: absolute;
  left: 0px;
  bottom: -10px;
  visibility: hidden;

  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.closed {
  position: absolute;
  inset: 0;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.closed-title {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 661px);
  top: 1867px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    font: 600 24px/64.44px 'RoobertRegular', sans-serif;
    position: absolute;
    left: calc(50% - 152px);
    top: 754px;
  }
}

.closed-title:hover {
  color: #beff00;
}
.closed-year-0 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 660px);
  top: 2000px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 55px;
    height: 25px;
    left: 42px;
    top: 806px;
  }
}
.closed-year-0:hover {
  background: #7d5bbe;
}
.closed-year-value {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 500 34.21px/42.45px 'RoobertRegular';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1080px) {
    font: 500 15px/42.45px 'RoobertRegular', sans-serif;
    top: -21px;
  }
}
.closed-year-1 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 511px);
  top: 2000px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 55px;
    height: 25px;
    left: 118px;
    top: 806px;
  }
}
.closed-year-1:hover {
  background: #7d5bbe;
}
.closed-year-2 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 351px);
  top: 2000px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 55px;
    height: 25px;
    left: 192px;
    top: 806px;
  }
}
.closed-year-2:hover {
  background: #7d5bbe;
}
.closed-year-3 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 191px);
  top: 2000px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 55px;
    height: 25px;
    left: 192px;
    top: 806px;
  }
}
.closed-year-3:hover {
  background: #7d5bbe;
}
.live-pill {
  width: 675.37px;
  height: 675.37px;
  position: absolute;
  right: -260px;
  top: -65px;
  transform-origin: 0 0;
  transform: rotate(3.639deg) scale(1, 1);

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.purple-bits {
  width: 1140px;
  height: 662.96px;
  position: absolute;
  left: -490px;
  top: 940px;
  transform-origin: 0 0;
  transform: rotate(4deg) scale(1, 1);

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}

.footer {
  position: absolute;
  inset: 0;
}

.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2242px;

  @media only screen and (max-width: 1080px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: unset;
    bottom: 104px !important;
  }
}

.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2390px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-width: 1080px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 57px !important;
  }
}

.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2390px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-width: 1080px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: unset;
    bottom: 36px !important;
    min-width: 200px;
    max-width: 220px;
  }
}

.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2390px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-width: 1080px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 36px !important;
  }
}

.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2354px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1080px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: unset;
    bottom: 57px !important;
  }
}

.open-campaigns {
  position: absolute;
  inset: 0;
  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.open-bg {
  background: #222222;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1417px;
  position: absolute;
  left: -1px;
  top: 305px;

  @media only screen and (max-width: 1080px) {
    background: #222222;
    border-radius: 30px 30px 0px 0px;
    width: 393px;
    height: 829px;
    position: absolute;
    left: -1px;
    top: 156px;
  }
}
.open-label {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 661px);
  top: 423px;
  width: 564px;
  height: 38.98px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    left: calc(50% - 152px);
    top: 170px;
  }
}
.open-label:hover {
  color: #beff00;
}
.open-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 350px);
  top: 874px;
  width: 875px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-width: 1080px) {
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 65px;
    top: 430px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/* ----------------------- */
/*      Rewards Table      */
/* ----------------------- */

.bg-closed-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1234px;
  position: absolute;
  left: -1px;
  top: 558px;
  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.close-cam {
  position: absolute;
  inset: 0;
  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.closed-open-title {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 661px);
  top: 688px;
  width: 675px;
  height: 51px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    left: 43px;
    top: 231px;
  }
}
.closed-open-title:hover {
  color: #beff00;
}
.closed-select {
  width: 189px;
  height: 65px;
  position: static;
  z-index: 1;
}
.closed-select-year {
  color: #f0f0ff;
  text-align: left;
  position: absolute;
  left: calc(50% + 556px);
  top: 688px;
  z-index: 1;

  @media only screen and (max-width: 1080px) {
    left: 265px;
    top: 226px;
    z-index: 100;
  }
}
.closed-select-year > div {
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  width: 210px;

  @media only screen and (max-width: 1080px) {
    width: 114px;
    font: 600 24px/37.23px 'RoobertRegular', sans-serif;
  }

  :first-child {
    height: 50px !important;
    :first-child {
      :first-child {
        :first-child {
          padding-top: 10px !important;
        }
      }
      :last-child {
        :first-child {
          top: -6px !important;
          font: 600 60px/64.44px 'RoobertRegular', sans-serif;

          @media only screen and (max-width: 1080px) {
            top: -10px !important;
            left: -10px !important;
            font: 600 24px/37.23px 'RoobertRegular', sans-serif;
          }
        }
      }
    }
  }
}
.closed-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 350px);
  top: 1214px;
  width: 675px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-width: 1080px) {
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 65px;
    top: 510px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.closed-table-mobile {
  visibility: hidden;

  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.closed-table {
  position: absolute;
  inset: 0;

  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}
.campaign-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 250px);
  top: 814px;
  width: 177px;
  height: 45px;
}
.level-completed {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 34px);
  top: 814px;
  width: 169px;
}
.score {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 814px;
  width: 169px;
}
.includes {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 308px);
  top: 814px;
  width: 169px;
}
.star-date {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 484px);
  top: 814px;
  width: 169px;
}
.end-date {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 668px);
  top: 814px;
  width: 169px;
}
.row-bg-0 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 869px;
}
.row-bg-1 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 999px;
}
.row-bg-2 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1129px;
}
.row-bg-3 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1259px;
}
.row-bg-4 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1389px;
}
.row-bg-5 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1519px;
}
.row-bg-6 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1649px;
}
.row-rewards-bg-0 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 885px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-0:hover {
  background: #7d5bbe;
}
.row-rewards-bg-1 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1016px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-1:hover {
  background: #7d5bbe;
}
.row-rewards-bg-2 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1146px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-2:hover {
  background: #7d5bbe;
}
.row-rewards-bg-3 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1276px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-3:hover {
  background: #7d5bbe;
}
.row-rewards-bg-4 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1406px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-4:hover {
  background: #7d5bbe;
}
.row-rewards-bg-5 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1536px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-5:hover {
  background: #7d5bbe;
}
.row-rewards-bg-6 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1666px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-6:hover {
  background: #7d5bbe;
}
.row-details-bg-0 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 885px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-0:hover {
  background: #7d5bbe;
}
.row-details-bg-1 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1016px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-1:hover {
  background: #7d5bbe;
}
.row-details-bg-2 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1146px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-2:hover {
  background: #7d5bbe;
}
.row-details-bg-3 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1276px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-3:hover {
  background: #7d5bbe;
}
.row-details-bg-4 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1406px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-4:hover {
  background: #7d5bbe;
}
.row-details-bg-5 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1536px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-5:hover {
  background: #7d5bbe;
}
.row-details-bg-6 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1666px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-6:hover {
  background: #7d5bbe;
}
.row-button-label {
  color: var(--black, #000000);
  text-align: center;
  font: 600 24.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
}
.row-name-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 905px;
  width: 185px;
  z-index: 1;
}
.row-name-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1035px;
  width: 185px;
  z-index: 1;
}
.row-name-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1165px;
  width: 185px;
  z-index: 1;
}
.row-name-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1295px;
  width: 185px;
  z-index: 1;
}
.row-name-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1425px;
  width: 185px;
  z-index: 1;
}
.row-name-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1555px;
  width: 185px;
  z-index: 1;
}
.row-name-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1685px;
  width: 185px;
  z-index: 1;
}
.row-level-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 905px;
  width: 154px;
}
.row-level-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1035px;
  width: 185px;
}
.row-level-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1165px;
  width: 185px;
}
.row-level-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1295px;
  width: 185px;
}
.row-level-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1425px;
  width: 185px;
}
.row-level-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1555px;
  width: 185px;
}
.row-level-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1685px;
  width: 185px;
}
.row-score-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 905px;
  width: 185px;
}
.row-score-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1035px;
  width: 169px;
  height: 39px;
}
.row-score-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1161px;
  width: 150px;
  height: 53px;
}
.row-score-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1295px;
  width: 185px;
  height: 53px;
}
.row-score-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1425px;
  width: 192px;
  height: 53px;
}
.row-score-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1556px;
  width: 176px;
  height: 53px;
}
.row-score-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1685px;
  width: 192px;
  height: 53px;
}
.row-includes-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 907px;
  width: 150px;
  height: 53px;
}
.row-includes-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1035px;
  width: 150px;
  height: 53px;
}
.row-includes-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1165px;
  width: 136px;
}
.row-includes-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1295px;
  width: 136px;
}
.row-includes-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1425px;
  width: 136px;
}
.row-includes-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1555px;
  width: 136px;
}
.row-includes-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1685px;
  width: 165px;
}
.row-start-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 906px;
  width: 150px;
  height: 53px;
}
.row-start-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1035px;
  width: 136px;
  height: 27px;
}
.row-start-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1161px;
  width: 150px;
  height: 53px;
}
.row-start-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1295px;
  width: 150px;
  height: 53px;
}
.row-start-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1425px;
  width: 150px;
  height: 53px;
}
.row-start-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1556px;
  width: 150px;
  height: 53px;
}
.row-start-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1685px;
  width: 150px;
  height: 53px;
}
.row-end-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 906px;
  width: 150px;
  height: 53px;
}
.row-end-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1035px;
  width: 150px;
  height: 53px;
}
.row-end-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1162px;
  width: 150px;
  height: 53px;
}
.row-end-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1296px;
  width: 150px;
  height: 53px;
}
.row-end-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1426px;
  width: 150px;
  height: 53px;
}
.row-end-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1557px;
  width: 150px;
  height: 53px;
}
.row-end-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1686px;
  width: 150px;
  height: 53px;
}

.row-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.row-bg-mobile {
  background: #000000;
  border-radius: 10px;
  width: 306px;
  height: 217px;
  position: absolute;
  left: 43px;
  top: 285px;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 25px;
  position: absolute;
  left: 50px;
  top: 305px;
  width: 144px;
  z-index: 10;
}
.tab-name {
  color: var(--black, #000000);
  text-align: center;
  font: 500 12px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.buttons2 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 25px;
  position: absolute;
  left: 200px;
  top: 305px;
  width: 144px;
  z-index: 100;
}
.group-65 {
  width: 268px;
  height: 143px;
  position: static;

  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.start-date-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 444px;
  width: 118px;

  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.score-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 391px;
  width: 48px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.includes-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 391px;
  width: 82px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.campaign-name-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 342px;
  width: 120px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.prod-8929 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 361px;
  width: 150px;
  height: 21px;
}
._2022-11-30 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 463px;
  width: 90px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
._100-000-000 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 411px;
  width: 90px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.level-completed-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 342px;
  width: 157px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
._12 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 361px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
._2022-12-10 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 463px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.subs-bits-mobile {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 411px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}
.end-date-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 444px;
  width: 78px;

  visibility: hidden;
  @media only screen and (max-width: 1080px) {
    visibility: visible;
  }
}

._2-current-score {
  width: 1332px;
  height: 75px;
  position: static;
}

.new-bg {
  background: linear-gradient(180deg, rgba(237, 255, 185, 1) 0%, rgba(190, 255, 0, 1) 100%);
  border-radius: 50%;
  width: 86px;
  height: 86px;
  position: absolute;
  left: calc(50% + 575px);
  top: 400px;
  cursor: pointer;
  z-index: 10;
}

.new {
  color: #000000;
  text-align: center;
  font: 600 69.14px/62.23px 'RoobertRegular', sans-serif;
  position: relative;
  width: 121px;
  height: 128.81px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(50% + 559px);
  top: 336px;
  cursor: pointer;
  z-index: 20;
}

.new:hover {
  color: #7d5bbe;
}

.no-mobile-view {
  visibility: hidden;

  @media only screen and (max-width: 1080px) {
    visibility: visible;
    color: #000000;
    text-align: center;
    font: 900 23.14px/25.23px 'RoobertRegular', sans-serif;
    position: absolute;
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    top: 420px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.closed-campaign-panel {
  width: 1316px;
  height: 120px;
  margin-bottom: 30px;
}
.closed-campaign-panel-bg {
  border-radius: 0px;
}
.closed-campaign-panel-label {
  color: #000000;
  text-align: left;
  font: 600 40px/37.23px 'RoobertRegular', sans-serif;
  width: 1110px;
  height: 43px;
  position: relative;
  top: -86px;
  left: 73px;
  cursor: pointer;
  z-index: 10;
}
.closed-campaign-panel-label:hover {
  color: #7d5bbe;
}
.closed-campaign-panel-plus {
  color: #000000;
  text-align: left;
  font: 500 40px/37.23px 'RoobertRegular', sans-serif;
  width: 920.14px;
  height: 43px;
  position: relative;
  top: -130px;
  left: 1240px;
  cursor: pointer;
  z-index: 10;
}
.closed-campaign-panel-plus:hover {
  color: #7d5bbe;
}

.campaign-list {
  width: 1320px;
  position: relative;
  left: calc(50% - 660px);
  top: 400px;
}

.opened-campaign-panel {
  position: absolute;
  left: calc(50% - 962px);
  top: calc(50% - 1055px);
  z-index: 11;

  visibility: visible;
  @media only screen and (max-width: 1080px) {
    visibility: hidden;
  }
}

.rectangle-12 {
  background: #111111;
  border-radius: 40px;
  width: 1316px;
  height: 1420px;
  position: absolute;
  left: 302px;
  top: 695px;
}
.rectangle-2 {
  background: #222222;
  border-radius: 111.98px;
  width: 1150px;
  height: 105px;
  position: absolute;
  left: 375px;
  top: 897px;
}
.rectangle-14 {
  background: #222222;
  border-radius: 111.98px;
  width: 1150px;
  height: 105px;
  position: absolute;
  left: 375px;
  top: 1751px;
}
.group-25 {
  width: 917.54px;
  height: 33px;
  position: static;
}
.start-date {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427px;
  top: 937px;
  width: 135.6px;
}
._2023-2-17-12-00-00-pm {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 933px;
  width: 415.54px;
}
.group-41 {
  width: 917.54px;
  height: 33px;
  position: static;
}
.channel-count {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427px;
  top: 1791px;
  width: 197px;
}
._0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1787px;
  width: 415.54px;
}
.current-score {
  width: 1316px;
  height: 120px;
  position: static;
}
.rectangle-6 {
  border-radius: 0px;
  position: absolute;
  left: 302px;
  top: 695px;
  overflow: visible;
}
.exp-ui-test {
  color: #000000;
  text-align: left;
  font: 600 40px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 375px;
  top: 735px;
  width: 980.14px;
  height: 43px;
  cursor: pointer;
}
.exp-ui-test:hover {
  color: #7d5bbe;
}
.opened-campaign-panel-minus {
  color: #000000;
  text-align: left;
  font: 500 40px/37.23px 'RoobertRegular', sans-serif;
  height: 43px;
  position: absolute;
  left: 1549px;
  top: 735px;
  cursor: pointer;
  z-index: 10;
}
.opened-campaign-panel-minus:hover {
  color: #7d5bbe;
}
.campaign-end-date {
  width: 1150px;
  height: 103.22px;
  position: static;
}
.rectangle-3 {
  background: #222222;
  border-radius: 110.1px;
  width: 1150px;
  height: 103.22px;
  position: absolute;
  left: 375px;
  top: 1030px;
}
.group-26 {
  width: 910.1px;
  height: 33px;
  position: static;
}
.end-date2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.5px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427.47px;
  top: 1070px;
  width: 133.32px;
}
._2023-2-17-12-00-00-pm2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 25.8px/32.02px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1065px;
  width: 408.56px;
}
.time-left {
  width: 1150px;
  height: 104.98px;
  position: static;
}
.rectangle-4 {
  background: #222222;
  border-radius: 111.98px;
  width: 1150px;
  height: 104.98px;
  position: absolute;
  left: 375px;
  top: 1161px;
}
.rectangle-42 {
  background: #222222;
  border-radius: 111.98px;
  width: 1150px;
  height: 104.98px;
  position: absolute;
  left: 375px;
  top: 1294px;
}
.group-24 {
  width: 917.54px;
  height: 33px;
  position: static;
}
.time-left2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427px;
  top: 1200px;
  width: 135.6px;
}
._13-h-20-min-43-sec {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1196px;
  width: 415.54px;
}
.group-53 {
  width: 917.54px;
  height: 33px;
  position: static;
}
.country {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427px;
  top: 1335px;
  width: 135.6px;
}
.tw {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1331px;
  width: 415.54px;
}
.rectangle-5 {
  background: #222222;
  border-radius: 110.1px;
  width: 1150px;
  height: 103px;
  position: absolute;
  left: 375px;
  top: 1427px;
}
.campaign-includes {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.5px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427.47px;
  top: 1467.22px;
  width: 133.32px;
}
.subs-bits {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1462px;
  width: 415.54px;
}
.next-lvl-score {
  width: 1150px;
  height: 165px;
  position: static;
}
.rectangle-8 {
  background: #222222;
  border-radius: 61.98px;
  width: 1150px;
  height: 165px;
  position: absolute;
  left: 375px;
  top: 1558px;
}
.group-28 {
  width: 917.54px;
  height: 98px;
  position: static;
}
.tiers {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 427px;
  top: 1628px;
  width: 223.08px;
}
._3-6-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 929px;
  top: 1590px;
  width: 540px;
  display: flex;
  flex-wrap: wrap;
}
.buttons4 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 334px;
  top: 1910px;
  width: 300px;
  cursor: pointer;
}
.buttons4:hover {
  background: #7d5bbe;
}
.tab-name4 {
  color: var(--black, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons5 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 653px;
  top: 2005px;
  width: 300px;
  cursor: pointer;
}
.buttons5:hover {
  background: #7d5bbe;
}

.buttons5-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 653px;
  top: 2005px;
  width: 300px;
}
.tab-name5 {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
}
.buttons6 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 967px;
  top: 2005px;
  width: 300px;
  cursor: pointer;
}
.buttons6:hover {
  background: #7d5bbe;
}

.buttons6-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 967px;
  top: 2005px;
  width: 300px;
}

.buttons7 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 967.5px;
  top: 1910px;
  width: 300px;
  cursor: pointer;
}
.buttons7:hover {
  background: #7d5bbe;
}

.buttons7-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 967.5px;
  top: 1910px;
  width: 300px;
}
.buttons8 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 334px;
  top: 2005px;
  cursor: pointer;
  width: 300px;
}
.buttons8:hover {
  background: #7d5bbe;
}

.buttons8-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 334px;
  top: 2005px;
  width: 300px;
}
.buttons9 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 656.5px;
  top: 1910px;
  cursor: pointer;
}
.buttons9:hover {
  background: #7d5bbe;
}
.buttons10 {
  background: #bb1411;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1356px;
  top: 719px;
  cursor: pointer;
}
.buttons10:hover {
  background: #7d5bbe;
}

.buttonsEdit {
  background: green;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1206px;
  top: 719px;
  cursor: pointer;
}
.buttonsEdit:hover {
  background: #7d5bbe;
}

.buttons-landing {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1282.5px;
  top: 1910px;
  width: 300px;
  cursor: pointer;
}
.buttons-landing:hover {
  background: #7d5bbe;
}

.buttons-landing-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1282.5px;
  top: 1910px;
  width: 300px;
}

.buttons-set-landing-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1282.5px;
  top: 2005px;
  width: 300px;
}

.buttons-set-landing {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15.7px 32.8px 15.7px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1282.5px;
  top: 2005px;
  width: 300px;
  cursor: pointer;
}
.buttons-set-landing:hover {
  background: #7d5bbe;
}
.tab-name6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: center;
  font: 500 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-new-bg {
  background: linear-gradient(180deg, rgba(237, 255, 185, 1) 0%, rgba(190, 255, 0, 1) 100%);
  border-radius: 50%;
  width: 52px;
  height: 52px;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  right: 15px;
}

.add-new {
  color: #000000;
  text-align: center;
  font: 600 49.14px/42.23px 'RoobertRegular', sans-serif;
  width: 121px;
  height: 128.81px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  right: -20px;
  margin-bottom: 5px;
}
.add-new:hover {
  color: #7d5bbe;
}

.pagination {
  position: relative;
  margin: auto;
  width: 50%;
  padding-left: 150px;
  z-index: 1;

  @media only screen and (max-width: 1080px) {
    position: absolute;
    left: 76px;
  }
}
</style>
