<template>
  <div @click.prevent="onClickPick">
    <slot></slot>
    <v-overlay v-if="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import * as filestack from 'filestack-js'

export default {
  props: {
    files: {
      type: [],
      default: []
    }
  },
  data() {
    return {
      client: filestack.init(process.env.VUE_APP_FILESTACK_API_KEY),
      isLoading: false
    }
  },
  methods: {
    onSuccess(result) {
      this.$emit('success', result)
    },
    onError(error) {
      this.$emit('error', error)
    },
    onClickPick() {
      this.isLoading = true
      this.upload()
        .then((result) => this.onSuccess(result))
        .catch((error) => this.onError(error))
        .finally(() => {
          this.isLoading = false
        })
    },
    upload() {
      const promises = []
      this.files.forEach((file) => {
        promises.push(this.client.upload(file))
      })
      return Promise.all(promises)
    }
  }
}
</script>

<style></style>
